import React, { ReactNode } from "react";

const Modal: React.FC<{
  children: ReactNode;
  width?: number;
}> = ({ children, width }) => {
  return (
    <>
      <div className="flex justify-center items-center overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black/30">
        <div className="relative w-auto my-6 mx-auto" style={{ width: width }}>
          <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative flex-auto">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
