import i18n from "i18next";

export const defaultGoogleMapCenter = {
  lat:
    process.env.REACT_APP_Country === "Lebanon"
      ? 33.8547
      : process.env.REACT_APP_Country === "Rwanda"
      ? -1.9403
      : 7.3697,
  lng:
    process.env.REACT_APP_Country === "Lebanon"
      ? 35.5236
      : process.env.REACT_APP_Country === "Rwanda"
      ? 29.8795
      : 12.3547,
};

declare global {
  interface String {
    truncate(maxLength: number, appendSuffix?: string): string;
    capitalize(): string;
    translateDigits(): string;
  }

  interface Number {
    translateDigits(): string;
  }
}

String.prototype.truncate = function (this: string, maxLength: number, appendSuffix: string = "..."): string {
  if (this.length <= maxLength) {
    return this;
  }
  return this.substring(0, maxLength - appendSuffix.length) + appendSuffix;
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.translateDigits = function () {
  if (i18n.language === "ar") {
    return this.replaceAll("0", "٠")
      .replaceAll("1", "١")
      .replaceAll("2", "٢")
      .replaceAll("3", "٣")
      .replaceAll("4", "٤")
      .replaceAll("5", "٥")
      .replaceAll("6", "٦")
      .replaceAll("7", "٧")
      .replaceAll("8", "٨")
      .replaceAll("9", "٩");
  }

  return this as string;
};

Number.prototype.translateDigits = function () {
  return this.toString().translateDigits();
};
