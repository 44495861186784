import React, { useContext, useEffect, useState } from "react";
import { AddOrEditModal } from "../../../../utils/Constants";
import { useTranslation } from "react-i18next";
import TextInput from "../../../UI/TextInput";
import CancelAndSaveButton from "../../../UI/CancelAndSaveButton";
import { VaccineContext } from "../../../../store/VaccineContext";
import LanguageBox from "../../../UI/LanguageBox";
import EN from "../../../../images/english.svg";
import FR from "../../../../images/french.svg";
import AR from "../../../../images/lebanon.svg";
import RW from "../../../../images/rwanda.svg";
import i18next from "i18next";
import ToggleSwitch from "../../../UI/ToggleSwitch";

const AddOrEditVaccination: React.FC<{
  className: string;
  typeOfModal: AddOrEditModal;
  onCancel: () => void;
  editVaccineTimeEn?: string;
  editVaccineTimeFr?: string;
  editVaccineTimeAr?: string;
  editVaccineTimeRw?: string;
  editDoseDetailEn?: string;
  editDoseDetailFr?: string;
  editDoseDetailAr?: string;
  editDoseDetailRw?: string;
  editOptional?: boolean;
  editPreBirth?: boolean;
  editVaccineId?: number;
  addOrEditVaccinationHandler: (
    _type: AddOrEditModal,
    _name: { en: string; fr: string; ar: string; rw: string },
    _dose_detail: { en: string; fr: string; ar: string; rw: string },
    _optional: boolean,
    _preBirth: boolean,
    editVaccineId?: number
  ) => void;
}> = ({
  className,
  onCancel,
  typeOfModal,
  editVaccineTimeEn,
  editVaccineTimeFr,
  editVaccineTimeAr,
  editVaccineTimeRw,
  editDoseDetailEn,
  editDoseDetailFr,
  editDoseDetailAr,
  editDoseDetailRw,
  editOptional,
  editPreBirth,
  editVaccineId,
  addOrEditVaccinationHandler,
}) => {
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const { t } = useTranslation();
  const [vaccineTimeEn, setVaccineTimeEn] = useState<string>();
  const [vaccineTimeFr, setVaccineTimeFr] = useState<string>();
  const [vaccineTimeAr, setVaccineTimeAr] = useState<string>();
  const [vaccineTimeRw, setVaccineTimeRw] = useState<string>();
  const [doseDetailEn, setDoseDetailEn] = useState<string>();
  const [doseDetailFr, setDoseDetailFr] = useState<string>();
  const [doseDetailAr, setDoseDetailAr] = useState<string>();
  const [doseDetailRw, setDoseDetailRw] = useState<string>();
  const [inputChanged, setInputChanged] = useState(true);
  // const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const { addLoading, editLoading } = useContext(VaccineContext);

  const [vaccineTimeEnErr, setVaccineTimeEnErr] = useState(false);
  const [vaccineTimeFrErr, setVaccineTimeFrErr] = useState(false);
  const [vaccineTimeArErr, setVaccineTimeArErr] = useState(false);
  const [vaccineTimeRwErr, setVaccineTimeRwErr] = useState(false);
  const [doseDetailEnErr, setDoseDetailEnErr] = useState(false);
  const [doseDetailFrErr, setDoseDetailFrErr] = useState(false);
  const [doseDetailArErr, setDoseDetailArErr] = useState(false);
  const [doseDetailRwErr, setDoseDetailRwErr] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [optional, setOptional] = useState(false);
  const [preBirth, setPreBirth] = useState(false);

  useEffect(() => {
    editVaccineTimeEn && inputChanged && setVaccineTimeEn(editVaccineTimeEn);
    editVaccineTimeFr && inputChanged && setVaccineTimeFr(editVaccineTimeFr);
    editVaccineTimeAr && inputChanged && setVaccineTimeAr(editVaccineTimeAr);
    editVaccineTimeRw && inputChanged && setVaccineTimeRw(editVaccineTimeRw);
    editDoseDetailEn && inputChanged && setDoseDetailEn(editDoseDetailEn);
    editDoseDetailFr && inputChanged && setDoseDetailFr(editDoseDetailFr);
    editDoseDetailAr && inputChanged && setDoseDetailAr(editDoseDetailAr);
    editDoseDetailRw && inputChanged && setDoseDetailRw(editDoseDetailRw);
    editOptional && inputChanged && setOptional(editOptional);
    editPreBirth && inputChanged && setPreBirth(editPreBirth);
  }, [
    vaccineTimeEn,
    vaccineTimeFr,
    vaccineTimeAr,
    vaccineTimeRw,
    doseDetailEn,
    doseDetailFr,
    doseDetailAr,
    doseDetailRw,
    editVaccineTimeEn,
    editVaccineTimeFr,
    editVaccineTimeAr,
    editVaccineTimeRw,
    editDoseDetailEn,
    editDoseDetailFr,
    editDoseDetailAr,
    editDoseDetailRw,
    optional,
    editOptional,
    preBirth,
    editPreBirth,
  ]);

  const timeEnInputChanged = (_time: string) => {
    setVaccineTimeEn(_time);
    setInputChanged(false);
    setVaccineTimeEnErr(false);
  };
  const timeFrInputChanged = (_time: string) => {
    setVaccineTimeFr(_time);
    setInputChanged(false);
    setVaccineTimeFrErr(false);
  };
  const timeArInputChanged = (_time: string) => {
    setVaccineTimeAr(_time);
    setInputChanged(false);
    setVaccineTimeArErr(false);
  };
  const timeRwInputChanged = (_time: string) => {
    setVaccineTimeRw(_time);
    setInputChanged(false);
    setVaccineTimeRwErr(false);
  };
  const doseEnInputChanged = (_time: string) => {
    setDoseDetailEn(_time);
    setInputChanged(false);
    setDoseDetailEnErr(false);
  };
  const doseFrInputChanged = (_time: string) => {
    setDoseDetailFr(_time);
    setInputChanged(false);
    setDoseDetailFrErr(false);
  };
  const doseArInputChanged = (_time: string) => {
    setDoseDetailAr(_time);
    setInputChanged(false);
    setDoseDetailArErr(false);
  };
  const doseRwInputChanged = (_time: string) => {
    setDoseDetailRw(_time);
    setInputChanged(false);
    setDoseDetailRwErr(false);
  };

  const cancelBtn = () => {
    setVaccineTimeEn(undefined);
    setVaccineTimeFr(undefined);
    setVaccineTimeAr(undefined);
    setVaccineTimeRw(undefined);
    setDoseDetailEn(undefined);
    setDoseDetailFr(undefined);
    setDoseDetailAr(undefined);
    setDoseDetailRw(undefined);
    setOptional(false);
    setPreBirth(false);
    setInputChanged(true);
    setSelectedLanguage("en");
    setVaccineTimeEnErr(false);
    setVaccineTimeFrErr(false);
    setVaccineTimeArErr(false);
    setVaccineTimeRwErr(false);
    setDoseDetailEnErr(false);
    setDoseDetailFrErr(false);
    setDoseDetailArErr(false);
    setDoseDetailRwErr(false);
    onCancel();
  };

  const validateInputs = () => {
    let isValid = true;

    if (!vaccineTimeEn) {
      setVaccineTimeEnErr(true);
      isValid = false;
    }
    if (!doseDetailEn) {
      setDoseDetailEnErr(true);
      isValid = false;
    }
    if (process.env.REACT_APP_Country === "Cameroon") {
      if (!vaccineTimeFr) {
        setVaccineTimeFrErr(true);
        isValid = false;
      }
      if (!doseDetailFr) {
        setDoseDetailFrErr(true);
        isValid = false;
      }
    } else if (process.env.REACT_APP_Country === "Lebanon") {
      if (!vaccineTimeFr) {
        setVaccineTimeFrErr(true);
        isValid = false;
      }
      if (!doseDetailFr) {
        setDoseDetailFrErr(true);
        isValid = false;
      }
      if (!vaccineTimeAr) {
        setVaccineTimeArErr(true);
        isValid = false;
      }
      if (!doseDetailAr) {
        setDoseDetailArErr(true);
        isValid = false;
      }
    } else if (process.env.REACT_APP_Country === "Rwanda") {
      if (!vaccineTimeRw) {
        setVaccineTimeRwErr(true);
        isValid = false;
      }
      if (!doseDetailRw) {
        setDoseDetailRwErr(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const saveBtn = (_type: AddOrEditModal) => {
    if (validateInputs()) {
      addOrEditVaccinationHandler(
        _type,
        { en: vaccineTimeEn!, fr: vaccineTimeFr!, ar: vaccineTimeAr!, rw: vaccineTimeRw! },
        { en: doseDetailEn!, fr: doseDetailFr!, ar: doseDetailAr!, rw: doseDetailRw! },
        optional,
        preBirth,
        editVaccineId
      );
    }
  };

  useEffect(() => {
    if (!addLoading) {
      setSelectedLanguage("en");
      cancelBtn();
    }
  }, [addLoading]);

  useEffect(() => {
    if (!editLoading) {
      setSelectedLanguage("en");
      cancelBtn();
    }
  }, [editLoading]);

  return (
    <>
      <div onClick={onCancel} className={`backdrop ${className}`}>
        <div
          onClick={(e) => e.stopPropagation()}
          className="sm:w-[492px] sm:h-[394px] bg-white cursor-auto w-8/12 h-auto rounded"
        >
          <form onSubmit={(e) => submitForm(e)} dir={i18next.language === "ar" ? "rtl" : "ltr"} className="mx-6">
            <div className="mt-[21px]">
              <div className="flex flex-wrap relative justify-between">
                <div className="text-lg font-semibold">
                  {typeOfModal === AddOrEditModal.ADD ? t("add_vaccination") : t("edit_vaccination")}
                </div>
                <div className="flex flex-row">
                  <label className="text-sm mx-2">{t("pre-birth")}</label>
                  <ToggleSwitch
                    checked={preBirth || false}
                    onClick={() => {
                      setPreBirth((preActive) => !preActive);
                      setInputChanged(false);
                    }}
                  />
                </div>
                <div className="flex flex-row">
                  <label className="text-sm mx-2">{t("optional")}</label>
                  <ToggleSwitch
                    checked={optional || false}
                    onClick={() => {
                      setOptional((preActive) => !preActive);
                      setInputChanged(false);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-wrap relative justify-between my-[8px]">
                <div className="text-[#414141] text-sm mb-2 sm:mb-0 max-w-[240px]">{t("fill_fields")}</div>
                <div>
                  <LanguageBox onChange={(lng) => setSelectedLanguage(lng)} selectedLanguage={selectedLanguage} />
                </div>
              </div>
            </div>

            {selectedLanguage === "en" && (
              <div className="pt-2">
                <label className="text-sm flex relative">
                  {t("vaccine_time")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={EN} alt={"English"} />
                </label>

                <div className="h-[62px] mt-1">
                  <TextInput
                    dir="ltr"
                    value={vaccineTimeEn || ""}
                    placeholder={t("vaccine_time") + " (EN)"}
                    onTextInputChanged={(input_time) => timeEnInputChanged(input_time)}
                    hasError={vaccineTimeEnErr}
                    errorMessage={vaccineTimeEnErr ? t("vaccine_time_error") : ""}
                  />
                </div>
              </div>
            )}
            {selectedLanguage === "fr" && (
              <div className="pt-2">
                <label className="text-sm flex relative">
                  {t("vaccine_time")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={FR} alt={"French"} />
                </label>

                <div className="h-[62px] mt-1">
                  <TextInput
                    dir="ltr"
                    value={vaccineTimeFr || ""}
                    placeholder={t("vaccine_time") + " (FR)"}
                    onTextInputChanged={(input_time) => timeFrInputChanged(input_time)}
                    hasError={vaccineTimeFrErr}
                    errorMessage={vaccineTimeFrErr ? t("vaccine_time_error") : ""}
                  />
                </div>
              </div>
            )}
            {selectedLanguage === "rw" && (
              <div className="pt-2">
                <label className="text-sm flex relative">
                  {t("vaccine_time")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={RW} alt={"kinyarwanda"} />
                </label>

                <div className="h-[62px] mt-1">
                  <TextInput
                    dir="ltr"
                    value={vaccineTimeRw || ""}
                    placeholder={t("vaccine_time") + " (RW)"}
                    onTextInputChanged={(input_time) => timeRwInputChanged(input_time)}
                    hasError={vaccineTimeRwErr}
                    errorMessage={vaccineTimeRwErr ? t("vaccine_time_error") : ""}
                  />
                </div>
              </div>
            )}
            {process.env.REACT_APP_Country === "Lebanon" && selectedLanguage === "ar" && (
              <div className="pt-2">
                <label className="text-sm flex relative">
                  {t("vaccine_time")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={AR} alt={"Arabic"} />
                </label>

                <div className="h-[62px] mt-1" dir="rtl">
                  <TextInput
                    value={vaccineTimeAr || ""}
                    placeholder={t("vaccine_time") + " (AR)"}
                    onTextInputChanged={(input_time) => timeArInputChanged(input_time)}
                    hasError={vaccineTimeArErr}
                    errorMessage={vaccineTimeArErr ? t("vaccine_time_error") : ""}
                  />
                </div>
              </div>
            )}
            {selectedLanguage === "en" && (
              <div>
                <label className="text-sm flex relative">
                  {t("dose_detail")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={EN} alt={"English"} />
                </label>

                <div className="h-[70px]">
                  <TextInput
                    className="mt-[5px]"
                    dir="ltr"
                    value={doseDetailEn || ""}
                    placeholder={t("dose_detail") + " (EN)"}
                    onTextInputChanged={(input_dose) => doseEnInputChanged(input_dose)}
                    hasError={doseDetailEnErr}
                    errorMessage={doseDetailEnErr ? t("dose_detail_error") : ""}
                  />
                </div>
              </div>
            )}
            {selectedLanguage === "fr" && (
              <div>
                <label className="text-sm flex relative">
                  {t("dose_detail")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={FR} alt={"French"} />
                </label>

                <div className="h-[70px]">
                  <TextInput
                    className="mt-[5px]"
                    dir="ltr"
                    value={doseDetailFr || ""}
                    placeholder={t("dose_detail") + " (FR)"}
                    onTextInputChanged={(input_dose) => doseFrInputChanged(input_dose)}
                    hasError={doseDetailFrErr}
                    errorMessage={doseDetailFrErr ? t("dose_detail_error") : ""}
                  />
                </div>
              </div>
            )}
            {selectedLanguage === "rw" && (
              <div>
                <label className="text-sm flex relative">
                  {t("dose_detail")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={RW} alt={"kinyarwanda"} />
                </label>

                <div className="h-[70px]">
                  <TextInput
                    className="mt-[5px]"
                    dir="ltr"
                    value={doseDetailRw || ""}
                    placeholder={t("dose_detail") + " (RW)"}
                    onTextInputChanged={(input_dose) => doseRwInputChanged(input_dose)}
                    hasError={doseDetailRwErr}
                    errorMessage={doseDetailRwErr ? t("dose_detail_error") : ""}
                  />
                </div>
              </div>
            )}
            {process.env.REACT_APP_Country === "Lebanon" && selectedLanguage === "ar" && (
              <div>
                <label className="text-sm flex relative">
                  {t("dose_detail")}
                  <span className="text-red-primary mx-1.5">*</span>
                  <img className="w-[16px] h-[11px] d-none mt-1.5" src={AR} alt={"Arabic"} />
                </label>

                <div className="h-[70px]" dir="rtl">
                  <TextInput
                    className="mt-[5px]"
                    value={doseDetailAr || ""}
                    placeholder={t("dose_detail") + " (AR)"}
                    onTextInputChanged={(input_dose) => doseArInputChanged(input_dose)}
                    hasError={doseDetailArErr}
                    errorMessage={doseDetailArErr ? t("dose_detail_error") : ""}
                  />
                </div>
              </div>
            )}

            <CancelAndSaveButton
              cancelBtn={cancelBtn}
              // disableSaveBtn={disableSaveBtn}
              saveBtn={saveBtn}
              type={typeOfModal}
              loading={addLoading || editLoading}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOrEditVaccination;
