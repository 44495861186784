import { useTranslation } from "react-i18next";
import MainContainer from "../../UI/MainContainer";
import PrimaryButton from "../../UI/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import i18next from "i18next";
import SearchSection from "../../UI/SearchSection";
import { useEffect, useState } from "react";
import DropdownWithCheckbox, { DropdownType } from "../../UI/DropdownWithCheckbox";
import {
  convertMsgStatusToDDItem,
  ListMessageObj,
  MessageStatus,
  MessageStatusDetail,
  RepeatFrequency,
} from "./MsgModel";
import MessagesTable, { MsgListSortConfig } from "./MessagesTable";
import IdValueType from "../../../types/IdValueType";
import { useHistory } from "react-router-dom";
import MsgManagementAPI from "../../../store/api/MsgManagementAPI";
import DatePicker from "../../UI/DatePicker";
import moment from "moment";
import NoResultMessage from "../../UI/NoResultMessage";

export type MsgListFilterConfig = {
  showFilterSection: boolean;
  statuses: Array<IdValueType>;
  sendTimeFrom?: Date;
  sendTimeTo?: Date;
  formattedSendTimeFrom?: string;
  formattedSendTimeTo?: string;
};

const allItem: IdValueType = { id: "", name: "", type: [] };

export type PaginationConfig = {
  pageSize: number;
  pageIndex: number;
};

const DEFAULT_FILTER_CONFIG: MsgListFilterConfig = {
  showFilterSection: false,
  statuses: [allItem],
};

export default function MessageManagement() {
  const { t } = useTranslation();
  const history = useHistory();
  const { getMessageList, stopSchedulingMessage } = MsgManagementAPI();

  const [messages, setMessages] = useState<ListMessageObj[]>([]);
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);
  const [filterConfig, setFilterConfig] = useState<MsgListFilterConfig | undefined>(undefined);
  const [sortConfig, setSortConfig] = useState<MsgListSortConfig | undefined>(undefined);
  const [tempFilterConfig, setTempFilterConfig] = useState<MsgListFilterConfig>(DEFAULT_FILTER_CONFIG);
  const [listIsLoading, setListIsLoading] = useState(true);

  let messageStatusesArray = convertMsgStatusToDDItem([allItem]);

  useEffect(() => {
    getMessages();
  }, [paginationConfig, searchPhrase, sortConfig, filterConfig]);

  const getMessages = () => {
    setListIsLoading(true);

    getMessageList(
      async (response: Response) => {
        const res = await response.json();
        setTotalDataCount(res.count);

        const messages: Array<ListMessageObj> = [];
        res.results.forEach((msg: any) => {
          messages.push({
            uniqueKey: msg.unique_key,
            recivers: msg.receiver_infos
              .filter((rc: any) => MessageStatus[rc.status as keyof typeof MessageStatus] !== MessageStatus.FI) // Remove future receivers
              .map((rc: any) => rc.full_name),
            futureReceivers: msg.receiver_infos
              .filter((rc: any) => MessageStatus[rc.status as keyof typeof MessageStatus] === MessageStatus.FI)
              .map((rc: any) => rc.full_name),
            repeatFrequency: msg.repeat_frequency,
            repeatFrequencyType: RepeatFrequency[msg.repeat_frequency_type as keyof typeof RepeatFrequency],
            message: msg.text_message["en"],
            msgStatus: MessageStatus[msg.status as keyof typeof MessageStatus],
            note: msg.note,
            sendTime: msg.send_date,
            receiversStatuses: msg.receiver_info_status
              .filter((rc: any) => MessageStatus[rc.type as keyof typeof MessageStatus] !== MessageStatus.FI) // Remove future receivers
              .map((msg: { type: string; count: number }) => ({
                status: MessageStatus[msg.type as keyof typeof MessageStatus],
                count: msg.count,
              })),
          });
        });

        setMessages(messages);
        setListIsLoading(false);
      },
      paginationConfig.pageIndex,
      paginationConfig.pageSize,
      searchPhrase,
      sortConfig,
      filterConfig
    );
  };

  const hasSomeFilter = () => {
    return !tempFilterConfig.statuses.includes(allItem) || tempFilterConfig.sendTimeFrom || tempFilterConfig.sendTimeTo;
  };

  return (
    <MainContainer>
      <main className="alignment mb-8">
        <div className="flex justify-between items-center">
          {/* Header Section */}
          <h3 data-testid="message_management-title" className="text-lg text-black27 font-semibold">
            {t("message_management")}
          </h3>

          <PrimaryButton onClick={() => history.push(`/messaging/add`)} primaryOrNot>
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faPlus}
                className={`${i18next.language === "ar" ? "ml-[10px]" : "mr-[10px]"} mt-[2px]`}
              />
              <label className="cursor-pointer">{t("add_health_message")}</label>
            </div>
          </PrimaryButton>
        </div>

        {/* Search and Filter Section */}
        <section className="flex bg-white mt-4 flex-col items-center justify-center">
          <SearchSection
            onSearch={setSearchPhrase}
            isFilterMode={tempFilterConfig.showFilterSection}
            onSliderClicked={() =>
              setTempFilterConfig({ ...tempFilterConfig, showFilterSection: !tempFilterConfig.showFilterSection })
            }
          />
          <hr className="h-[6px] bg-gray-f5 border-none" />

          {tempFilterConfig.showFilterSection && (
            <div className="flex w-full px-8 flex-wrap">
              <div className="flex flex-1 pb-6 flex-col sm:flex-row">
                <div className="mr-6 rtl:ml-6 rtl:mr-0">
                  <label className="text-sm text-black41">{t("status")}</label>
                  <DropdownWithCheckbox
                    type={DropdownType.PRIMARY}
                    extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
                    onOptionSelected={(option) => {
                      setTempFilterConfig({ ...tempFilterConfig, statuses: option });
                    }}
                    keys={messageStatusesArray}
                    selectedKey={tempFilterConfig.statuses}
                    getValueByKey={(key) => {
                      if (key === "") return t("all");
                      return messageStatusesArray.find((msgStatus) => msgStatus.name === key)?.name ?? "";
                    }}
                  />
                </div>

                <div className="mx-1">
                  <label className="text-sm text-black41">{t("send_time")}</label>
                  <DatePicker
                    selected={tempFilterConfig.sendTimeFrom ?? null}
                    onChange={(newDate: Date | null) =>
                      setTempFilterConfig({
                        ...tempFilterConfig,
                        sendTimeFrom: newDate ?? undefined,
                        formattedSendTimeFrom: moment(newDate).locale("en").format("DD/MM/yyyy"),
                      })
                    }
                    placeholderText={t("from")}
                  />
                </div>

                <div className="flex h-full items-end mt-2 sm:mt-0">
                  <DatePicker
                    selected={tempFilterConfig.sendTimeTo ?? null}
                    onChange={(newDate: Date | null) =>
                      setTempFilterConfig({
                        ...tempFilterConfig,
                        sendTimeTo: newDate ?? undefined,
                        formattedSendTimeTo: moment(newDate).locale("en").format("DD/MM/yyyy"),
                      })
                    }
                    placeholderText={t("to")}
                  />
                </div>
              </div>

              <div className="flex flex-1 items-center justify-end">
                {hasSomeFilter() && (
                  <label
                    className="link mx-6"
                    onClick={() => {
                      setTempFilterConfig({ ...DEFAULT_FILTER_CONFIG, showFilterSection: true });
                      setFilterConfig(undefined);
                    }}
                  >
                    {t("reset")}
                  </label>
                )}
                <PrimaryButton onClick={() => setFilterConfig(tempFilterConfig)} primaryOrNot title={t("apply")} />
              </div>
            </div>
          )}
        </section>

        <section className="flex bg-white h-full mt-2 px-6 py-4 items-center justify-center">
          {messages.length === 0 && !listIsLoading ? (
            <NoResultMessage noResultOrNoData={true} />
          ) : (
            <MessagesTable
              data={messages}
              pageCount={Math.ceil(totalDataCount / paginationConfig.pageSize)}
              listIsLoading={listIsLoading}
              pagiantionConfig={paginationConfig}
              setPaginationConfig={setPaginationConfig}
              sortChanged={(sortConfig?: MsgListSortConfig) => setSortConfig(sortConfig)}
              totalDataCount={totalDataCount}
              onStopClick={(messageUniqueKey: string) => {
                stopSchedulingMessage(messageUniqueKey, async (response: Response) => {
                  if (response.ok) {
                    getMessages();
                  }
                });
              }}
              onPreviewClick={(messageUniqueKey: string) =>
                history.push(`/messaging/view`, { messageId: messageUniqueKey })
              }
            />
          )}
        </section>
      </main>
    </MainContainer>
  );
}
