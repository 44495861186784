import React, { useContext } from "react";
import MainContainer from "../../UI/MainContainer";
import { useTranslation } from "react-i18next";
import MainFooter from "../../UI/MainFooter";
import HeaderLink from "./HeaderLink";
import Button from "../../UI/Button";
import { ButtonTypes } from "../../../utils/Constants";
import { AuthContext } from "../../../store/AuthContext";
import placeholder from "../../../images/user-placeholder.svg";
import profile from "../../../images/Profile.png";
import i18next from "i18next";

const Profile: React.FC<{
  editProfileHandler: () => void;
}> = ({ editProfileHandler }) => {
  const { t } = useTranslation();

  const { authData } = useContext(AuthContext);

  return (
    <MainContainer>
      <div className="alignment">
        <div className="text-[18px] capitalize">{t("profile")}</div>
        <div className="rounded shadow bg-white mt-[26px] pb-[81px] relative z-10">
          <HeaderLink />
          <div className="mt-[30px] w-[97%] mx-auto ">
            <div className="w-[142px] h-[142px] rounded">
              <img
                src={!authData.avatar ? placeholder : authData.avatar}
                alt={t("profile_image")}
                className="w-full h-full object-contain rounded "
              />
            </div>
            <div className="mt-10 ">
              <div className="text-[#272727] text-sm">
                <label>
                  {t("first_name")}
                  <span className="text-red-primary ml-1.5">*</span>
                </label>
                <div className="mt-[15px] text-[#414141] capitalize">{authData.firstName}</div>
              </div>

              <div className="mt-[46px] text-[#272727] text-sm">
                <label className="mt-5 ">
                  {t("last_name")}
                  <span className="text-red-primary ml-1.5">*</span>
                </label>
                <div className="mt-[15px] text-[#414141] capitalize">{authData.lastName}</div>
              </div>
              <div className="mt-[53px] z-20 relative">
                <Button children={t("edit_profile")} onClick={editProfileHandler} type={ButtonTypes.PRIMARY} />
              </div>
            </div>
          </div>
          <div
            className={`absolute w-[552px] bottom-0 ${
              i18next.language === "ar" ? "left-0" : "right-0"
            } invisible sm:visible z-0`}
          >
            <img
              src={profile}
              alt={t("background")}
              style={i18next.language === "ar" ? { transform: "scaleX(-1)" } : {}}
            />
          </div>
        </div>

        <MainFooter />
      </div>
    </MainContainer>
  );
};

export default Profile;
