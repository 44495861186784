import Modal from "../../../UI/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrash } from "@fortawesome/pro-light-svg-icons";
import NumberInput from "../../../UI/NumberInput";
import { useState } from "react";
import { DynamicContactWeekRange } from "./ContactModel";
import PrimaryButton from "../../../UI/PrimaryButton";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

interface Props {
  onSelectRange: (selectedRange: Array<DynamicContactWeekRange>) => void;
  onClose: () => void;
}

interface RangeInput {
  value: DynamicContactWeekRange;
  error?: string;
}

export default function DynamicContactModal(props: Props) {
  const { t } = useTranslation();
  const [ranges, setRanges] = useState<Array<RangeInput>>([
    {
      value: {
        fromWeek: "0",
        toWeek: "0",
      },
    },
  ]);

  const addRange = () => {
    const currRanges = [...ranges];
    currRanges.push({
      value: {
        fromWeek: "0",
        toWeek: "0",
      },
    });

    setRanges(currRanges);
  };

  const updateRange = (targetIndex: number, newFrom: string, newTo: string) => {
    const targetItem = ranges[targetIndex];
    targetItem.value = { fromWeek: newFrom, toWeek: newTo };
    targetItem.error = undefined;

    setRanges((prevItems) =>
      prevItems.map((item, index) => (index === targetIndex ? { ...item, ...targetItem } : item))
    );
  };

  const updateRangeError = (targetIndex: number) => {
    const currRanges = [...ranges];
    currRanges[targetIndex].error = t("invalid_range_error_desc");

    setRanges(currRanges);
  };

  const removeRange = (targetIndex: number) => {
    const currRanges = [...ranges];
    currRanges.splice(targetIndex, 1);

    setRanges(currRanges);
  };

  const allRangesAreValid = () => {
    let allAreValid = true;
    ranges.forEach((range, index) => {
      const fromValue = Number(range.value.fromWeek);
      const toValue = Number(range.value.toWeek);

      if ((fromValue === 0 && toValue === 0) || fromValue > toValue || toValue < fromValue) {
        updateRangeError(index);
        allAreValid = false;
      }
    });

    return allAreValid;
  };

  return (
    <Modal width={1140}>
      <div className="flex flex-col p-6">
        <div className="flex items-center justify-between">
          <label className="text-black41 font-semibold">{t("dynamic_contact_list")}</label>
          <FontAwesomeIcon icon={faClose} className="cursor-pointer text-lg" onClick={props.onClose} />
        </div>
        <p className="mt-8 text-gray-41">{t("dynamic_list_desc")}</p>
        <label className="mt-8 mx-2 text-gray-41">{t("age_range_week")}</label>
        {ranges.map((range, index) => {
          return (
            <div className="flex flex-col mt-2" key={index}>
              <div className="flex items-center">
                <NumberInput
                  className={`placeholder:text-gray-d7 w-[120px] mx-2 ${range.error && "border-red-primary"}`}
                  value={range.value.fromWeek}
                  placeholder={t("from")}
                  onNumberInputChanged={(value) => updateRange(index, value, range.value.toWeek)}
                />
                <NumberInput
                  className={`placeholder:text-gray-d7 w-[120px] ${range.error && "border-red-primary"}`}
                  value={range.value.toWeek}
                  placeholder={t("from")}
                  onNumberInputChanged={(value) => updateRange(index, range.value.fromWeek, value)}
                />

                {index > 0 && (
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="mx-3 cursor-pointer hover:text-blue-primary"
                    onClick={() => removeRange(index)}
                  />
                )}
              </div>

              {range.error && <label className="mt-1 mx-2 text-red-primary font-light">{range.error}</label>}
            </div>
          );
        })}

        <label className="mt-4 w-fit text-blue-primary cursor-pointer" onClick={addRange}>
          <FontAwesomeIcon icon={faAdd} className="mx-1" />
          {t("add_new_age_range")}
        </label>

        <div className="flex items-center justify-end mt-8 mr-6">
          <label className="link text-gray-41 mx-6" onClick={props.onClose}>
            {t("cancel")}
          </label>
          <PrimaryButton
            onClick={() => {
              if (allRangesAreValid()) {
                props.onSelectRange(ranges.map((rangeInput) => rangeInput.value));
                props.onClose();
              }
            }}
            primaryOrNot
            title={t("select")}
          />
        </div>
      </div>
    </Modal>
  );
}
