import { CircularProgress } from "@mui/material";

interface Props {
  size?: string | number;
  color?: string;
}

export default function CircularLoading(props: Props) {
  return <CircularProgress size={props.size ?? 64} style={{ color: props.color ?? "#11589a" }} />;
}
