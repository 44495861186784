import React from "react";

const NumberInput: React.FC<{
  className?: string;
  value: string;
  placeholder: string;
  onNumberInputChanged: (value: string) => void;
  min?: string;
  max?: string;
  disabled?: boolean;
}> = ({ className, value, placeholder, onNumberInputChanged, min, max, disabled }) => {
  return (
    <input
      disabled={disabled}
      className={`search-filter__input outline-none ${className}`}
      type="number"
      value={value}
      placeholder={placeholder}
      onChange={(e) => onNumberInputChanged(e.target.value)}
      min={min || "0"}
      max={max}
    />
  );
};

export default NumberInput;
