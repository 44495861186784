import { Area } from "../../components/main/dashboard/Dashboard";
import useAPI from "../../hooks/useAPI";
import Clinic from "../../types/Clinic";
import { useCallback } from "react";

const DashboardAPI = () => {
  const { sendRequest } = useAPI();

  const getDashboard = useCallback(
    (processGetDashboard: (clinics: Clinic[], areas: Area[]) => void) => {
      sendRequest(
        {
          url: "dashboard/",
        },
        async (response) => {
          if (response.ok) {
            const result = await response.json();

            const clinicArr = result.clinics.map((clinic: any) => {
              return {
                slug: clinic.slug,
                title: clinic.name,
                address: clinic.address,
                latitude: +clinic.latitude,
                longitude: +clinic.longitude,
                staffCount: clinic.staff_count,
                patientCount: clinic.patient_count,
                defaulterCount: clinic.defaulter_count,
                savedCount: clinic.saved_count,
              } as Clinic;
            });

            const areaArr = result.area.map((area: any) => {
              return {
                id: area.id,
                name: area.name,
                description: area.description,
                latitude: area.center_latitude,
                longitude: area.center_longitude,
                radius: area.radius,
                coordinates: area.coordinates,
                clinic: area.clinic,
                children: area.children,
                defaulters: area.defaulters,
              } as Area;
            });
            processGetDashboard(clinicArr, areaArr);
          }
        }
      );
    },
    [sendRequest]
  );

  const getClinicAdminDashboard = useCallback(
    (processGetDashboard: (clinics: Clinic, areas: Area[]) => void) => {
      sendRequest(
        {
          url: "ca-dashboard/",
        },
        async (response) => {
          if (response.ok) {
            const result = await response.json();

            const clinic = {
              slug: result.clinic.slug,
              title: result.clinic.name,
              address: result.clinic.address,
              latitude: +result.clinic.latitude,
              longitude: +result.clinic.longitude,
              staffCount: result.clinic.staff_count,
              patientCount: result.clinic.patient_count,
              defaulterCount: result.clinic.defaulter_count,
              savedCount: result.clinic.saved_count,
            } as Clinic;

            const areaArr = result.area.map((area: any) => {
              return {
                id: area.id,
                name: area.name,
                description: area.description,
                latitude: area.center_latitude,
                longitude: area.center_longitude,
                radius: area.radius,
                coordinates: area.coordinates,
                clinic: area.clinic,
                children: area.children,
                defaulters: area.defaulters,
              } as Area;
            });
            processGetDashboard(clinic, areaArr);
          }
        }
      );
    },
    [sendRequest]
  );

  return { getDashboard, getClinicAdminDashboard };
};

export default DashboardAPI;
