import React, { useContext, useEffect, useState } from "react";
import { Column } from "react-table";
import ChildrenType from "../../../types/Children";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { SelectedChildrenContext } from "../../../store/SelectedChildrenContext";
import i18next from "i18next";
import { faCheckCircle, faTimesCircle, faClock, faQuestion } from "@fortawesome/pro-light-svg-icons";
import { ChildVaccine } from "./Children";
import moment from "moment";
import "moment/locale/ar";

const ChildrenRow: React.FC<{
  child: ChildrenType;
  dynamicColumns: Column<ChildrenType>[];
}> = ({ child, dynamicColumns }) => {
  const full_name = child.full_name;
  const area = child.area;
  const clinic = child.clinic;
  const phone_number = child.phone_number;
  const national_id = child.national_id;
  const slug = child.slug;
  const { selectedChildren, selectChildren, deselectChildren } = useContext(SelectedChildrenContext);
  const checked = selectedChildren.indexOf(slug) !== -1;
  const [childVaccinesList, setChildVaccinesList] = useState<ChildVaccine[]>([]);

  useEffect(() => {
    vaccineHandler();
  }, [dynamicColumns]);

  type IconColorPair = [IconDefinition, string?];

  const statusIconHandler = (status: string): IconColorPair => {
    const statusMap: Record<string, IconColorPair> = {
      V: [faCheckCircle, "#8abb2a"],
      M: [faTimesCircle, "#d11c1c"],
      S: [faClock, "#f78e01"],
    };
    return statusMap[status] || [faQuestion, "#ffffff"];
  };

  const vaccineHandler = () => {
    const newChildVaccinesList = [];
    for (const element of dynamicColumns) {
      const vaccineAccessor = element.accessor;
      if (typeof vaccineAccessor === "string") {
        const vaccineData = child?.[vaccineAccessor];
        if (vaccineData && vaccineData.name === vaccineAccessor) {
          newChildVaccinesList.push(vaccineData);
        } else {
          // Vaccines which was not injected.
          newChildVaccinesList.push({
            id: "",
            name: vaccineAccessor,
            status: null,
            date: null,
          });
        }
      }
    }

    setChildVaccinesList(newChildVaccinesList);
  };

  const formatDateInArabic = (dateString: string) => {
    return dateString
      .split("")
      .map((char) => {
        const number = Number(char);
        return isNaN(number) ? char : "٠١٢٣٤٥٦٧٨٩"[number];
      })
      .join("");
  };

  return (
    <tr className="border-t border-gray-df">
      <td
        className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky ${
          i18next.language === "ar" ? "border-l right-0 text-right" : "border-r left-0"
        }`}
      >
        <div className="flex items-center">
          <div
            className={`table__checkbox grid place-items-center ${i18next.language === "ar" ? "ml-4" : "mr-4"} ${
              checked && "border-blue-primary bg-blue-primary"
            }`}
            onClick={() => {
              if (checked) {
                deselectChildren(slug);
              } else {
                selectChildren(slug);
              }
            }}
          >
            {checked && <FontAwesomeIcon icon={faCheck} className="text-sm text-white" />}
          </div>
          {full_name}
        </div>
      </td>
      <td
        className={`border-t td-firefox border-gray-df table__cell text-sm text-[#414141] ${
          i18next.language === "ar" && "text-right"
        }`}
        dir="ltr"
      >
        {phone_number}
      </td>
      <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">{national_id}</td>
      <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">{clinic}</td>
      <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">{area}</td>
      {childVaccinesList.length > 0 &&
        childVaccinesList.map((vaccine, index) => (
          <td key={index} className="border-t td-firefox border-gray-df table__cell text-sm text-black41">
            <div className="flex flex-col text-center">
              <span>
                <FontAwesomeIcon
                  key={index}
                  className="mr-1 text-[16px]"
                  style={{ color: statusIconHandler(vaccine.status)[1] }}
                  icon={statusIconHandler(vaccine.status)[0]}
                />
              </span>
              <span>
                {i18next.language === "ar" && vaccine.date !== null
                  ? formatDateInArabic(moment(vaccine.date, "DD/MM/YYYY").format("DD/MM/YYYY"))
                  : vaccine.date}
              </span>
              <span>{vaccine.clinic}</span>
            </div>
          </td>
        ))}
    </tr>
  );
};

export default ChildrenRow;
