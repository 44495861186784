import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DashboardCard: React.FC<{
  title: string;
  value: number;
  valueTestId: string;
  children: React.ReactNode;
  clinicNames?: string[];
  onClinicChanged?: (clinicName: string) => void;
}> = (props) => {
  const { title, value, valueTestId, children, clinicNames, onClinicChanged } =
    props;

  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedClinicName, setSelectedClinicName] = useState("");

  const menuRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => setShowMenu((prev) => !prev);

  const handleClinicSelect = (clinicName: string) => {
    setSelectedClinicName(clinicName);
    setShowMenu(false);
    if (onClinicChanged) onClinicChanged(clinicName);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  if (selectedClinicName === "all" && i18next.language === "fr") {
    setSelectedClinicName("tous");
  } else if (selectedClinicName === "tous" && i18next.language === "en") {
    setSelectedClinicName("all");
  }

  return (
    <article
      data-testid="dashboard-card"
      style={{ zIndex: 10 }}
      className="bg-white rounded shadow-md p-6 z-10 flex flex-col justify-between"
    >
      <h4 className="text-base text-black41 font-semibold">{title}</h4>
      <div className="mt-8">
        {clinicNames && (
          <div className="relative flex flex-col items-start">
            <label className="capitalize text-sm text-black41 mb-2">
              {t("clinic")}:
            </label>
            <div
              className="flex justify-between w-full bg-gray-88 rounded py-3 px-6 cursor-pointer"
              onClick={toggleMenu}
            >
              <p
                data-testid="selected__clinic-name"
                className="capitalize text-sm text-blue-primary"
              >
                {selectedClinicName !== "" ? selectedClinicName : t("all")}
              </p>
              <FontAwesomeIcon
                className="text-blue-primary"
                icon={faAngleDown}
              />
            </div>
            {showMenu && (
              <div
                ref={menuRef}
                className="absolute left-0 right-0 bottom-0 transform translate-y-full bg-white rounded shadow py-1 z-40 max-h-32 overflow-y-auto scroll-firefox"
              >
                <ul>
                  <li
                    className="dashboard-card__list-item !text-sm !text-gray-50 px-6"
                    onClick={() => handleClinicSelect("all")}
                  >
                    {t("all")}
                  </li>
                  {clinicNames.map((clinicName, index) => (
                    <li
                      key={index}
                      className="dashboard-card__list-item !text-sm !text-gray-50 px-6"
                      onClick={() => handleClinicSelect(clinicName)}
                    >
                      {clinicName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        <div className="flex items-center mt-6" dir="ltr">
          {children}
          <p
            data-testid={valueTestId}
            className="text-base text-black41 font-semibold ml-6"
          >
            {i18next.language === "ar"
              ? Intl.NumberFormat("ar-EG").format(value)
              : value}
          </p>
        </div>
      </div>
    </article>
  );
};

export default DashboardCard;
