import React, { useContext, useEffect, useState } from "react";
import MainContainer from "../../UI/MainContainer";
import HeaderLink from "./HeaderLink";
import { useTranslation } from "react-i18next";
import { ButtonTypes } from "../../../utils/Constants";
import MainFooter from "../../UI/MainFooter";
import profile from "../../../images/Profile.png";
import Button from "../../UI/Button";
import { AuthContext } from "../../../store/AuthContext";
import placeholder from "../../../images/user-placeholder.svg";
import TextInput from "../../UI/TextInput";
import { faTrash, faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import i18next from "i18next";

const EditProfile: React.FC<{
  cancel: () => void;
  photoChangeHandler: (isChanged: boolean) => void;
  saveHandler: (photoDeleted: boolean, photo: string, firstname: string, lastname: string) => void;
  loading: boolean;
}> = ({ cancel, saveHandler, loading, photoChangeHandler }) => {
  const { t } = useTranslation();
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const { authData } = useContext(AuthContext);
  const [firstname, setFirstname] = useState(authData.firstName);
  const [lastname, setLastname] = useState(authData.lastName);
  const [photoDeleted, setPhotoDeleted] = useState(false);
  const [photo, setPhoto] = useState("");
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [showInfo, setShowInfo] = useState(false);
  const [preview, setPreview] = useState<string>();
  const [photoPath, setPhotoPath] = useState(authData.avatar);

  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  useEffect(() => {
    photoChangeHandler(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = Object(event.target.files)[0];
    photoChangeHandler(true);
    const objectUrl = URL.createObjectURL(fileUpload);
    setPreview(objectUrl);
    setPhoto(fileUpload);
  };

  const DeleteImageHandler = () => {
    setPhotoPath("");
    setPhoto("");
    setPhotoDeleted(true);
  };

  const cancelHandler = () => {
    cancel();
  };

  return (
    <MainContainer>
      <div className="alignment">
        <div className="text-[18px] capitalize">{t("edit_profile")}</div>
        <div className="rounded shadow bg-white mt-[26px] pb-20 relative">
          <HeaderLink />
          <div className="mt-[30px] w-[97%] mx-auto ">
            <div className="w-[142px] h-[142px] rounded relative">
              {photo === "" && (
                <img
                  src={!photoPath ? placeholder : authData.avatar}
                  alt={t("profile_image")}
                  className="w-full h-full object-contain rounded "
                />
              )}
              {preview && (
                <img src={preview} alt={t("profile_image")} className="w-full h-full object-contain rounded " />
              )}

              {!photoPath && (
                <div className="absolute">
                  <button onClick={handleClick}>
                    <FontAwesomeIcon
                      className={`text-[40px] absolute top-[-93px] ${
                        i18next.language === "ar" ? "right-[51px]" : "left-[51px]"
                      } text-gray-500`}
                      icon={faPlusCircle}
                    />
                  </button>
                  <input onChange={(e) => handleChange(e)} ref={hiddenFileInput} type="file" className="hidden" />
                </div>
              )}
              {!photoDeleted && showInfo && (
                <div className=" text-[#414141] absolute left-[120px] top-[104px] bg-white shadow text-[11px]">
                  {t("delete")}
                </div>
              )}
              {photoPath && (
                <div
                  onMouseOver={() => setShowInfo(true)}
                  onMouseOut={() => setShowInfo(false)}
                  onClick={DeleteImageHandler}
                  className="rounded-full bg-white w-[22px] h-[22px] absolute left-[125px] top-[125px] hover:cursor-pointer"
                >
                  <FontAwesomeIcon className="text-sm absolute top-[3px] left-[3px] text-[#11589a]" icon={faTrash} />
                </div>
              )}
            </div>
            <form onSubmit={(e) => submitForm(e)} className="mt-10 ">
              <div className="text-[#272727] text-sm relative z-10">
                <label>
                  {t("first_name")}
                  <span className="text-red-primary ml-1.5">*</span>
                </label>
                <div className="mt-[5px] text-[#414141]">
                  <TextInput
                    errorMessage={t("first_name_is_required")}
                    hasError={firstname === ""}
                    onTextInputChanged={(first_name) => {
                      setFirstname(first_name);
                    }}
                    placeholder={t("first_name")}
                    value={firstname}
                    className="sm:w-1/3"
                  />
                </div>
              </div>

              <div className="mt-[30px] text-[#272727] text-sm relative z-10">
                <label className="mt-5 ">
                  {t("last_name")}
                  <span className="text-red-primary ml-1.5">*</span>
                </label>
                <div className="mt-[5px] ">
                  <TextInput
                    errorMessage={t("last_name_is_required")}
                    hasError={lastname === ""}
                    onTextInputChanged={(lastName) => {
                      setLastname(lastName);
                    }}
                    placeholder={t("last_name")}
                    value={lastname}
                    className=" sm:w-1/3"
                  />
                </div>
              </div>
              <div className="flex">
                <div className="mt-10 md:w-1/3 flex flex-row-reverse sm:mx-[0px] mx-auto md:pr-0 pr-[30px] relative z-10">
                  <Button
                    disabled={firstname.length === 0 || lastname.length === 0}
                    onClick={() => saveHandler(photoDeleted, photo, firstname, lastname)}
                    type={ButtonTypes.PRIMARY}
                  >
                    {loading ? <CircularProgress size={18} style={{ color: "white" }} /> : t("save")}
                  </Button>

                  <button
                    onClick={cancelHandler}
                    className="rounded px-[25px] mx-6 capitalize text-[#414141] hover:bg-[#F5F3F2]"
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            className={`absolute w-[552px] bottom-0 ${
              i18next.language === "ar" ? "left-0" : "right-0"
            } invisible sm:visible z-0`}
          >
            <img
              src={profile}
              alt={t("background")}
              style={i18next.language === "ar" ? { transform: "scaleX(-1)" } : {}}
            />
          </div>
        </div>

        <MainFooter />
      </div>
    </MainContainer>
  );
};

export default EditProfile;
