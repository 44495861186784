import { t } from "i18next";
import Modal from "../../UI/Modal";
import PrimaryButton from "../../UI/PrimaryButton";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  onResendClicked: () => void;
}

export default function ResendModal(props: Props) {
  const { t } = useTranslation();

  return (
    <Modal>
      <div className="flex flex-col py-4 px-6 text-gray-41">
        <h1 className="font-semibold">{t("resend_message_title")}</h1>
        <p className="my-11 text-sm">{t("resend_message_desc")}</p>

        <div className="flex items-center justify-end">
          <label className="link text-gray-41 mr-11" onClick={props.onClose}>
            {t("cancel")}
          </label>
          <PrimaryButton
            onClick={() => {
              props.onResendClicked();
              props.onClose();
            }}
            primaryOrNot
            title={t("yes_resend")}
          />
        </div>
      </div>
    </Modal>
  );
}
