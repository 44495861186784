import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import Clinic from "../../../types/Clinic";
import { ButtonTypes, MAP_CONTAINER_STYLE } from "../../../utils/Constants";
import isPhoneNumberValid, { PHONE_NUMBER_REGEX } from "../../../utils/isPhoneNumberValid";
import ClinicsAPI from "../../../store/api/clinicsAPI";
import TextInput from "../../UI/TextInput";
import Button from "../../UI/Button";
import ToggleSwitch from "../../UI/ToggleSwitch";
import DropdownWithCheckbox, { DropdownType } from "../../UI/DropdownWithCheckbox";
import IdValueType from "../../../types/IdValueType";
import { defaultGoogleMapCenter } from "../../../utils/utils";
import useRole from "../../../hooks/useRole";

const ClinicModal: React.FC<{
  isAdd: boolean;
  className: string;
  onBackdrop: () => void;
  onCancel: () => void;
  clinic?: Clinic;
  onClinicAdded?: (
    slug: string,
    title: string,
    active: boolean,
    phoneNumber: string,
    address: string,
    areas: number[],
    areaNames: IdValueType[],
    latitude: number,
    longitude: number
  ) => void;
  onClinicEdited?: () => void;
}> = ({ isAdd, className, onCancel, clinic, onClinicAdded, onClinicEdited }) => {
  const { t } = useTranslation();
  const { isClinicAdmin } = useRole();
  const [title, setTitle] = useState<string | undefined>(clinic ? clinic.title : undefined);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    clinic && clinic.phoneNumber ? clinic.phoneNumber : undefined
  );
  const [active, setActive] = useState(clinic ? clinic.status === "active" : true);
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(clinic ? { latitude: clinic.latitude, longitude: clinic.longitude } : null);
  const [address, setAddress] = useState<string | undefined>(clinic ? clinic.address : undefined);
  const [areasList, setAreasList] = useState<IdValueType[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<IdValueType[] | undefined>(clinic ? clinic.areaNames : undefined);
  const [addressError, setAddressError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [combined, setCombined] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    mapIds: JSON.parse(process.env.REACT_APP_MAP_IDS!),
  });
  const modalRef = useRef<HTMLDivElement>(null);
  const { addClinic, editClinic, getAreasList } = ClinicsAPI();

  useEffect(() => {
    getAreasList(async (response: Response) => {
      if (response.ok) {
        const result = await response.json();
        setAreasList(result.names);
      } else {
        console.log("Failed to fetch areas");
      }
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        resetInputs();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    if (address === undefined) return;

    if (address === "") {
      setLocation(null);
      setAddressError(t("address_is_invalid__message"));
    } else {
      setAddressError("");
    }
  }, [address]);

  useEffect(() => {
    if (clinic && clinic.areaNames && !combined && areasList.length > 0) {
      const combinedAreas = [...clinic.areaNames, ...areasList];
      setAreasList(combinedAreas);
      setCombined(true);
    }
  }, [areasList, clinic, combined]);

  const resetInputs = () => {
    setTitle("");
    setActive(true);
    setPhoneNumber("");
    setSelectedAreas(undefined);
    setLocation(null);
    setAddress("");
  };

  const onMapClicked = (e: google.maps.MapMouseEvent) => {
    setLocation({
      latitude: e.latLng!.lat(),
      longitude: e.latLng!.lng(),
    });

    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({
        location: { lat: e.latLng!.lat(), lng: e.latLng!.lng() },
      })
      .then((response) => {
        let maxLength = 0;
        let formattedAddress = "";
        for (const result of response.results) {
          if (result.address_components.length >= maxLength) {
            maxLength = result.address_components.length;
            formattedAddress = result.formatted_address;
          }
        }
        setAddress(formattedAddress);
      })
      .catch((error) => console.log(error));
  };

  const onLocationIconClicked = () => {
    if (address) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, function (results, status) {
        if (status === "OK" && results) {
          setLocation({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        } else {
          setAddressError(t("address_is_invalid__message"));
        }
      });
    }
    return true;
  };

  const onSaveButtonClicked = () => {
    const geocoder = new google.maps.Geocoder();
    setLoading(true);
    geocoder.geocode({ address }, function (results, status) {
      if (status === "OK" && results) {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        setLocation({
          latitude,
          longitude,
        });
        if (isAdd) {
          onAddClinic(latitude, longitude);
        } else {
          onEditClinic(latitude, longitude);
        }
      } else {
        setLoading(false);
        setAddressError(t("address_is_invalid__message"));
      }
    });
  };

  const onAddClinic = (latitude: number, longitude: number) => {
    setLoading(true);
    addClinic(
      title!,
      active,
      phoneNumber || "",
      address!,
      selectedAreas!.map((area) => Number(area.id)),
      latitude,
      longitude,
      async (response) => {
        const result = await response.json();
        if (response.ok && onClinicAdded) {
          setLoading(false);
          onClinicAdded(
            result.slug,
            title!,
            result.active,
            phoneNumber!,
            address!,
            selectedAreas!.map((area) => Number(area.id)),
            selectedAreas!,
            latitude,
            longitude
          );
        }
      }
    );
  };

  const onEditClinic = (latitude: number, longitude: number) => {
    setLoading(true);
    editClinic(
      clinic!.slug,
      title!,
      phoneNumber || "",
      address!,
      latitude,
      longitude,
      selectedAreas!.map((area) => Number(area.id)),
      async (response) => {
        if (response.ok && onClinicEdited) {
          setLoading(false);
          onClinicEdited();
        }
      },
      active
    );
  };

  return (
    <div className={`backdrop ${className}`} onClick={() => onCancel()}>
      <section
        className="clinic__modal cursor-auto bg-white shadow-md p-6 w-full overflow-auto 480:w-[492px] 480:rounded"
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg text-gray-27 font-semibold">{isAdd ? t("add_clinic") : t("edit_clinic")}</h3>
        <div className="mt-10">
          <label className="text-sm text-grey-27 capitalize">
            {t("title")}
            <span className="text-red-primary"> *</span>
          </label>
          <TextInput
            className="mt-1"
            value={title || ""}
            placeholder=""
            hasError={title === ""}
            errorMessage={t("title_is_required")}
            onTextInputChanged={(value) => setTitle(value)}
          />
        </div>
        {!isClinicAdmin() && (
          <div className="flex items-center mt-8">
            <label className="text-sm text-grey-27 capitalize">{t("active")}</label>
            <ToggleSwitch className="mx-5" checked={active} onClick={() => setActive((preActive) => !preActive)} />
          </div>
        )}
        <div className="mt-8">
          <p className="text-sm text-black41 first-letter:uppercase">{t("add_clinic__message")}</p>
        </div>
        <div className="h-40 mt-4">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={MAP_CONTAINER_STYLE}
              center={location ? { lat: location.latitude, lng: location.longitude } : defaultGoogleMapCenter}
              zoom={8}
              options={{ mapId: "cb06d0000f670630" }}
              onClick={onMapClicked}
            >
              {location && (
                <Marker
                  position={{ lat: location.latitude, lng: location.longitude }}
                  icon={{
                    url: "/map-marker.svg",
                  }}
                />
              )}
            </GoogleMap>
          )}
        </div>
        <div className="mt-6">
          <label className="text-sm text-grey-27 capitalize">
            {t("address")}
            <span className="text-red-primary"> *</span>
          </label>
          <div className="relative">
            <TextInput
              className="mt-1"
              inputClassName="pr-10"
              value={address || ""}
              placeholder=""
              hasError={addressError !== undefined && addressError !== ""}
              errorMessage={addressError || ""}
              onTextInputChanged={(value) => setAddress(value)}
            />
            {addressError === "" && address !== undefined && (
              <div
                onClick={() => onLocationIconClicked()}
                className="absolute right-2 top-[9px] w-7 h-7 cursor-pointer rounded grid place-items-center text-blue-primary hover:bg-blue-e1"
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <label className="text-sm text-grey-27 capitalize">
            {t("areas")}
            <span className="text-red-primary"> *</span>
          </label>
          <DropdownWithCheckbox
            type={DropdownType.SECONDARY}
            extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
            onOptionSelected={(option) => {
              setSelectedAreas(option);
            }}
            keys={areasList}
            selectedKey={selectedAreas || []}
            getValueByKey={(key) => key}
            hasError={selectedAreas && selectedAreas.length <= 0}
            errorMessage={t("area_is_required")}
          />
        </div>
        <div className="mt-4">
          <label className="text-sm text-grey-27 capitalize">{t("phone_number")}</label>
          <TextInput
            className="mt-1"
            value={phoneNumber || ""}
            placeholder=""
            hasError={!isPhoneNumberValid(phoneNumber, true)}
            errorMessage={t("number_valid")}
            onTextInputChanged={(value) => setPhoneNumber(value)}
            onKeyPress={(event) => {
              if (!PHONE_NUMBER_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
        <div className="flex justify-end mt-11">
          <Button className="mx-4" onClick={() => onCancel()} type={ButtonTypes.CANCEL}>
            {t("cancel")}
          </Button>
          <Button
            disabled={
              title === undefined ||
              title === "" ||
              addressError === undefined ||
              addressError !== "" ||
              !isPhoneNumberValid(phoneNumber, true) ||
              loading ||
              selectedAreas === undefined ||
              selectedAreas.length <= 0
            }
            type={ButtonTypes.PRIMARY}
            onClick={onSaveButtonClicked}
          >
            {loading ? <CircularProgress size={18} style={{ color: "white", marginTop: 5 }} /> : t("save")}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default ClinicModal;
