import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { Snackbar, SnackbarContent, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

import AuthAPI from "../../store/api/authAPI";
import usePasswordValidation, { passwordValidator } from "../../hooks/UsePasswordValidation";
import { validationState, ButtonTypes, AlertModalTypes } from "../../utils/Constants";
import captchaValidation from "../../utils/captchaValidation";
import AuthContainer from "../UI/AuthContainer";
import IncorrectUrl from "../pages/IncorrectUrl";
import PasswordInput from "../UI/PasswordInput";
import PasswordProgressBar from "../UI/PasswordProgressBar";
import ErrorBanner from "../UI/ErrorBanner";
import Button from "../UI/Button";
import AlertModal from "../UI/AlertModal";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const enum errorType {
  FIRST_PASSWORD,
  SECOND_PASSWORD,
  CAPTCHA,
  NONE,
}

const CAPTCHA_ACTION = "resetPassword__saveChanges__submit";

const ResetPassword = () => {
  const {
    password: firstPassword,
    setPassword: setFirstPassword,
    passwordHasError: firstPasswordHasError,
    setPasswordHasError: setFirstPasswordHasError,
  } = usePasswordValidation();
  const {
    password: secondPassword,
    setPassword: setSecondPassword,
    passwordHasError: secondPasswordHasError,
    setPasswordHasError: setSecondPasswordHasError,
  } = usePasswordValidation((_secondPassword) => _secondPassword === firstPassword);
  const [firstPasswordStrength, setFirstPasswordStrength] = useState<{
    label: string;
    value: number;
    color: string;
  }>({
    label: "",
    value: 0,
    color: "",
  });
  const [linkValidation, setLinkValidation] = useState<boolean>();
  const [noLongerMessage, setNoLongerMessage] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [error, setError] = useState<{
    type: errorType;
    message?: string;
  }>({ type: errorType.NONE });

  const { uid, hashToken } = useParams<{ uid: string; hashToken: string }>();
  const { linkValidationRequest, resetPassword } = AuthAPI();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const WEAK = {
    label: t("weak"),
    value: 40,
    color: "#D11C1C",
  };
  const SO_SO = {
    label: t("medium"),
    value: 60,
    color: "#F78E00",
  };
  const STRONG = {
    label: t("strong"),
    value: 100,
    color: "#0AA69F",
  };

  useEffect(() => {
    linkValidationRequest(uid, hashToken, async (response) => {
      if (response.ok) {
        setLinkValidation(true);
      } else {
        const result = await response.json();
        setNoLongerMessage(result["URL"]);
        setLinkValidation(false);
      }
    });
  }, [uid, hashToken, linkValidationRequest]);

  useEffect(() => {
    switch (firstPasswordHasError) {
      case validationState.INVALID:
        if (firstPassword === "")
          setError({
            type: errorType.FIRST_PASSWORD,
            message: t("new_password_is_required"),
          });
        else
          setError({
            type: errorType.FIRST_PASSWORD,
            message: t("first_password_error"),
          });
        break;
      case validationState.VALID:
        if (secondPassword !== undefined && firstPassword !== secondPassword) {
          setError({
            type: errorType.SECOND_PASSWORD,
            message: t("second_password_error"),
          });
        } else {
          setError({ type: errorType.NONE });
        }
        break;
      case validationState.WAITING:
        setError({ type: errorType.NONE });
        break;
    }
  }, [firstPasswordHasError]);

  useEffect(() => {
    if (secondPasswordHasError === validationState.INVALID) {
      if (secondPassword === "")
        setError({
          type: errorType.SECOND_PASSWORD,
          message: t("repeat_new_password_is_required"),
        });
      else
        setError({
          type: errorType.SECOND_PASSWORD,
          message: t("second_password_error"),
        });
    } else {
      setError({ type: errorType.NONE });
    }
  }, [secondPasswordHasError]);

  useEffect(() => {
    if (firstPassword !== undefined) {
      const passwordStrength = zxcvbn(firstPassword);

      if (passwordValidator(firstPassword)) {
        if (passwordStrength.score <= 2) {
          setFirstPasswordStrength(SO_SO);
        } else if (passwordStrength.score >= 3) {
          setFirstPasswordStrength(STRONG);
        }
      } else {
        setFirstPasswordStrength(WEAK);
      }
    }
  }, [firstPassword]);

  const onResetPasswordFormSubmitted = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (
      firstPassword !== undefined &&
      secondPassword !== undefined &&
      passwordValidator(firstPassword) &&
      firstPassword === secondPassword
    ) {
      captchaValidation(
        CAPTCHA_ACTION,
        (token) => {
          resetPassword(uid, hashToken, firstPassword, token, processResetPasswordResponse);
        },
        () => {
          setLoading(false);
          setSomethingWentWrong(true);
        }
      );
    }
  };

  const processResetPasswordResponse = async (response: Response) => {
    if (response.ok) {
      setLoading(false);
      setShowSnackbar(true);
    } else {
      const result = await response.json();
      setLoading(false);
      switch (response.status) {
        case 400:
          setError({
            type: errorType.FIRST_PASSWORD,
            message: t("first_password_error"),
          });
          break;
        case 404:
          setLinkValidation(false);
          setNoLongerMessage(result["URL"]);
          break;
        case 406:
          setError({ type: errorType.CAPTCHA, message: result.captcha });
          break;
      }
    }
  };

  const firstPasswordIsEmpty = firstPassword === "" || firstPassword === undefined;
  const saveChangeDisabled =
    firstPassword === undefined ||
    secondPassword === undefined ||
    !passwordValidator(firstPassword) ||
    firstPassword !== secondPassword;

  if (linkValidation === undefined) {
    return null;
  }

  return (
    <>
      <Snackbar
        dir={i18next.language === "ar" ? "rtl" : "ltr"}
        open={showSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setShowSnackbar(false);
          history.replace("/login");
          window.location.reload();
        }}
      >
        <SnackbarContent message={<p>The password updated successfully.</p>} />
      </Snackbar>
      {linkValidation ? (
        <AuthContainer>
          <form className="flex flex-col mt-[55px]" onSubmit={onResetPasswordFormSubmitted}>
            <h3 className="text-center text-gray-41 text-base font-semibold first-letter:uppercase">
              create new password
            </h3>
            {error.type === errorType.CAPTCHA && <ErrorBanner className="mt-8" message={error.message || ""} />}
            <p className="text-center mt-8 mb-6 text-sm text-gray-41 first-letter:uppercase">enter your new password</p>
            <PasswordInput
              hasError={error.type === errorType.FIRST_PASSWORD}
              onPasswordChange={(value) => {
                setFirstPassword(value);
                setFirstPasswordHasError(validationState.WAITING);
              }}
              placeholder="new password"
            />
            {firstPasswordIsEmpty && (
              <p className="text-xs text-gray-70 mt-1 first-letter:uppercase">{t("new_password_description")}</p>
            )}
            {!firstPasswordIsEmpty && (
              <div className="flex items-center mt-1 relative" dir="ltr">
                <PasswordProgressBar {...firstPasswordStrength} className="flex-1 mr-2" />
                <FontAwesomeIcon
                  data-testid="info-icon"
                  icon={faInfoCircle}
                  className="cursor-pointer text-sm text-gray-70"
                  onMouseOver={() => setShowHint(true)}
                  onMouseLeave={() => setShowHint(false)}
                />
                {showHint && (
                  <p className="absolute w-[165px] bg-white rounded p-2 text-xs text-gray-27 -right-4 top-[-92px] z-10 shadow first-letter:uppercase">
                    {t("new_password_description")}
                  </p>
                )}
              </div>
            )}
            {error.type === errorType.FIRST_PASSWORD && <p className="input__error-message mt-1">{error.message}</p>}
            <PasswordInput
              hasError={error.type === errorType.SECOND_PASSWORD}
              onPasswordChange={(value) => {
                setSecondPassword(value);
                setSecondPasswordHasError(validationState.WAITING);
              }}
              disabled={error.type === errorType.FIRST_PASSWORD || firstPasswordIsEmpty}
              placeholder="repeat your password"
            />
            {error.type === errorType.SECOND_PASSWORD && <p className="input__error-message mt-2">{error.message}</p>}
            <Button
              type={ButtonTypes.PRIMARY}
              className="mt-10"
              disabled={saveChangeDisabled}
              dataAction={CAPTCHA_ACTION}
            >
              {loading && <CircularProgress size={18} style={{ color: "white" }} />}
              {!loading && "save changes"}
            </Button>
          </form>
          {somethingWentWrong && (
            <AlertModal
              type={AlertModalTypes.SOMETHING_WENT_WRONG}
              onAction={() => window.location.reload()}
              onBackdrop={() => setSomethingWentWrong(false)}
            />
          )}
        </AuthContainer>
      ) : (
        <IncorrectUrl message={noLongerMessage} />
      )}
    </>
  );
};

export default ResetPassword;
