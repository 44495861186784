import { useLocation, useHistory } from "react-router-dom";
import MainContainer from "../../UI/MainContainer";
import Button from "../../UI/Button";
import { ButtonTypes } from "../../../utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import {
  CIMA_PHONE,
  convertMsgStatusToDDItem,
  MessageObj,
  MessageStatus,
  MsgConfig,
  ReceiverListObj,
  RepeatFrequency,
} from "./MsgModel";
import { useEffect, useState } from "react";
import LanguageBox from "../../UI/LanguageBox";
import MsgManagementAPI from "../../../store/api/MsgManagementAPI";
import { getStatusBoxStyle } from "./MsgStatusComponent";
import EN from "../../../images/english.svg";
import FR from "../../../images/french.svg";
import AR from "../../../images/lebanon.svg";
import RW from "../../../images/rwanda.svg";
import SearchSection from "../../UI/SearchSection";
import DropdownWithCheckbox, { DropdownType } from "../../UI/DropdownWithCheckbox";
import IdValueType from "../../../types/IdValueType";
import DatePicker from "../../UI/DatePicker";
import PrimaryButton from "../../UI/PrimaryButton";
import moment from "moment";
import FilterTag from "../../UI/FilterTag";
import ReceiverTable, { RIListSortConfig } from "./ReceiverTable";
import ResendModal from "./ResendModal";
import { useTranslation } from "react-i18next";

interface Props {
  messageId?: string;
}

export type ReceiverInfoFilter = {
  showFilterSection: boolean;
  statuses: Array<IdValueType>;
  sendTimeFrom?: Date;
  sendTimeTo?: Date;
  formattedSendTimeFrom?: string;
  formattedSendTimeTo?: string;
};

const allItem: IdValueType = { id: "", name: "", type: [] };
const DEFAULT_FILTER_CONFIG: ReceiverInfoFilter = {
  showFilterSection: false,
  statuses: [allItem],
};

const RECEIVER_TEMPLATE = [
  { id: "1", receiver: "+63 91310134445", language: "en", status: MessageStatus.ST, sendTime: new Date() },
  { id: "2", receiver: "+63 91310134445", language: "fr", status: MessageStatus.FD, sendTime: new Date() },
  { id: "3", receiver: "John Doe", language: "fr", status: MessageStatus.DD, sendTime: new Date() },
  { id: "3", receiver: "John Wick", language: "en", status: MessageStatus.SG, sendTime: new Date() },
];

export interface RIListPaginationConfig {
  pageIndex: number;
  pageSize: number;
}

export default function PreviewMessage(props: Props) {
  const { t } = useTranslation();
  const location = useLocation<Props>();
  const history = useHistory();
  const messageId = location.state?.messageId;

  const { getMessage, resendMessage, getMessageReceivers } = MsgManagementAPI();

  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");
  const [message, setMessage] = useState<MessageObj | undefined>(undefined);
  const [receivers, setReceivers] = useState<Array<ReceiverListObj> | undefined>(undefined);
  const [totalReceiversCount, setTotalReceiversCount] = useState<number>(0);

  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);
  const [filterConfig, setFilterConfig] = useState<ReceiverInfoFilter | undefined>(undefined);
  const [tempFilterConfig, setTempFilterConfig] = useState<ReceiverInfoFilter>(DEFAULT_FILTER_CONFIG);
  const [sortConfig, setSortConfig] = useState<RIListSortConfig | undefined>(undefined);
  const [paginationConfig, setPaginationConfig] = useState<RIListPaginationConfig>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [receiverListIsLoading, setReceiverListIsLoading] = useState(false);
  const [showResendModal, setShowResendModal] = useState<{ receiverId: string | null; showModal: boolean }>({
    receiverId: null,
    showModal: false,
  });

  const messageStatusesArray = convertMsgStatusToDDItem([allItem]);

  const hasSomeFilter = () => {
    return (
      (filterConfig?.showFilterSection && !filterConfig?.statuses.includes(allItem)) ||
      filterConfig?.sendTimeFrom ||
      filterConfig?.sendTimeTo
    );
  };

  useEffect(() => {
    getMessage(messageId!, async (response: Response) => {
      const res = await response.json();

      const messageConfig: MsgConfig = {
        note: res.message_info.note,
        textMessage: res.message_info.text_message,
        firstSendDate: res.message_info.first_send_date,
        endDate: res.message_info.end_date,
        stoppedDate: res.message_info.stopped_date,
        repeatFrequencyType: RepeatFrequency[res.message_info.repeat_frequency_type as keyof typeof RepeatFrequency],
        repeatFrequency: res.message_info.repeat_frequency,
        repeatForNewContactsOnly: false,
      };

      setMessage({
        dynamicGroups: res.dynamic_groups,
        staticGroups: res.static_groups,
        status: res.status,
        messageInfo: messageConfig,
      });
    });
  }, []);

  useEffect(() => {
    setReceiverListIsLoading(true);

    getMessageReceivers(
      messageId!,
      async (response: Response) => {
        const res = await response.json();

        setTotalReceiversCount(res.count);
        const allReceivers: ReceiverListObj[] = [];
        res.results.map((rcr: any) => {
          const receiver: ReceiverListObj = {
            uniqueKey: rcr.unique_key,
            receiver: rcr.full_name ?? rcr.phone_number,
            language: rcr.preferred_language,
            status: MessageStatus[rcr.status as keyof typeof MessageStatus],
            sendTime: rcr.updated_at,
          };

          allReceivers.push(receiver);
        });

        setReceivers(allReceivers);
        setReceiverListIsLoading(false);
      },
      paginationConfig.pageIndex,
      paginationConfig.pageSize,
      searchPhrase,
      sortConfig,
      filterConfig
    );
  }, [paginationConfig, sortConfig, filterConfig]);

  useEffect(() => {
    if (filterConfig) setTempFilterConfig(filterConfig);
  }, [filterConfig]);

  let sendTimeFilterTagValue = "";
  if (filterConfig?.sendTimeFrom && filterConfig.sendTimeTo) {
    sendTimeFilterTagValue = `${filterConfig.formattedSendTimeFrom} - ${filterConfig.formattedSendTimeTo}`;
  } else if (filterConfig?.sendTimeFrom && !filterConfig?.sendTimeTo) {
    sendTimeFilterTagValue = `${t("from")} ${filterConfig.formattedSendTimeFrom}`;
  } else if (!filterConfig?.sendTimeFrom && filterConfig?.sendTimeTo) {
    sendTimeFilterTagValue = `${t("to")} ${filterConfig.formattedSendTimeTo}`;
  }

  return (
    <MainContainer>
      <main className="alignment mb-8">
        <div className="flex items-center justify-between">
          <h3 className="text-lg text-black27 font-semibold">{t("view_health_message")}</h3>
          <Button
            className="bg-opacity-0 text-blue-primary"
            type={ButtonTypes.SECONDARY}
            onClick={() => history.goBack()}
          >
            <div className="flex items-center" dir="ltr">
              <FontAwesomeIcon icon={faArrowLeft} className="mx-1 text-base" />
              {t("back")}
            </div>
          </Button>
        </div>

        <section className="flex flex-col bg-white mt-4">
          <div className="flex items-center p-6 border-b-[1px]">
            <label className="text-gray-41 mr-2">{`${t("from").capitalize()}:`}</label>
            <label>{`CIMA ${CIMA_PHONE}`}</label>
          </div>

          <div className="flex items-center p-6">
            <label className="text-gray-41 mr-2">{`${t("to").capitalize()}:`}</label>
            <div className="flex flex-1 mr-2">
              <label>{message?.staticGroups.join(", ")}</label>
              <label>
                {message?.dynamicGroups.map((dg, index) => {
                  return `${t("children")} ${dg.from_range} ${t("to")} ${dg.to_range} ${t("weeks")} ${
                    index !== message.dynamicGroups.length - 1 ? ", " : ""
                  }`;
                })}
              </label>
            </div>
          </div>
        </section>

        <section className="flex flex-col bg-white mt-1 p-6">
          <div
            className="flex items-center mb-8"
            style={{
              justifyContent: message?.messageInfo.stoppedDate ? "space-between" : "flex-end",
            }}
          >
            {message?.messageInfo.stoppedDate && (
              <label className={`px-2 py-[6px] rounded-[3px] mr-1 ${getStatusBoxStyle(MessageStatus.FD)}`}>
                {`${t("stopped")} ${message?.messageInfo.stoppedDate}`}
              </label>
            )}
            <LanguageBox onChange={(lng) => setSelectedLanguage(lng)} selectedLanguage={selectedLanguage} />
          </div>

          <div className="flex flex-col">
            <div className="flex">
              <label className="font-semibold text-gray-41">{t("message")}</label>
              <span className="text-red-primary mx-1.5">*</span>

              <img
                className="w-[16px] h-[11px] d-none mt-1.5"
                src={
                  selectedLanguage === "en"
                    ? EN
                    : selectedLanguage === "fr"
                    ? FR
                    : selectedLanguage === "ar"
                    ? AR
                    : selectedLanguage === "rw"
                    ? RW
                    : ""
                }
                alt="country flags"
              />
            </div>

            <p className="mt-4 text-gray-41">{message?.messageInfo?.textMessage?.[selectedLanguage]}</p>

            <label className="mt-12 font-semibold text-gray-41">{t("note")}</label>
            <p className="mt-4 text-gray-41">{message?.messageInfo.note ?? "--"}</p>

            <label className="mt-14 font-bold text-gray-41">{t("schedule_send_message")}</label>

            <div className="flex flex-wrap">
              <div className="flex w-full md:w-1/3 flex-col mt-7">
                <label className="font-semibold text-gray-41">{t("first_send_date")}</label>
                <label className="mt-2">
                  {message?.messageInfo.firstSendDate?.toString().translateDigits() ?? "--"}
                </label>
              </div>

              <div className="flex w-full md:w-1/3 flex-col mt-7">
                <label className="font-semibold text-gray-41">{t("end_time")}</label>
                <label className="mt-2">{message?.messageInfo.endDate?.toString() ?? "--"}</label>
              </div>

              <div className="flex w-full md:w-1/3 flex-col mt-7">
                <label className="font-semibold text-gray-41">{t("repeat_frequency")}</label>
                <label className="mt-2">{t(message?.messageInfo.repeatFrequencyType.toString().toLowerCase()!)}</label>
              </div>

              <div className="flex w-full md:w-1/3 flex-col mt-7">
                <label className="font-semibold text-gray-41">{t("repeat_every")}</label>
                <label className="mt-2">
                  {message?.messageInfo.repeatFrequency === 0
                    ? "--"
                    : message?.messageInfo.repeatFrequency.translateDigits()}
                </label>
              </div>
            </div>
          </div>
        </section>

        {showResendModal.showModal && (
          <ResendModal
            onClose={() => setShowResendModal({ receiverId: null, showModal: false })}
            onResendClicked={() => {
              resendMessage(showResendModal.receiverId!, (response) => {
                if (response.status === 200) {
                  setPaginationConfig((preValue) => ({ ...preValue, pageIndex: 0 })); // Just for refreshing the list.
                }
              });
            }}
          />
        )}

        {receivers && receivers.length > 0 && (
          <section>
            <div className="flex flex-col bg-white mt-4">
              <SearchSection
                onSearch={(search) => {
                  setSearchPhrase(search);
                  setPaginationConfig((preValue) => ({ ...preValue, pageIndex: 0 }));
                }}
                isFilterMode={tempFilterConfig.showFilterSection}
                onSliderClicked={() =>
                  setTempFilterConfig({ ...tempFilterConfig, showFilterSection: !tempFilterConfig.showFilterSection })
                }
              />

              {tempFilterConfig.showFilterSection && (
                <div className="flex w-full px-8 flex-wrap">
                  <div className="flex flex-1 pb-6 flex-col sm:flex-row">
                    <div className="mr-6 rtl:mr-0 rtl:ml-6">
                      <label className="text-sm text-black41">{t("status")}</label>
                      <DropdownWithCheckbox
                        type={DropdownType.PRIMARY}
                        extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
                        onOptionSelected={(option) => {
                          setTempFilterConfig({ ...tempFilterConfig, statuses: option });
                        }}
                        keys={messageStatusesArray}
                        selectedKey={tempFilterConfig.statuses}
                        getValueByKey={(key) => {
                          if (key === "") return t("all");
                          return messageStatusesArray.find((msgStatus) => msgStatus.name === key)?.name ?? "";
                        }}
                      />
                    </div>

                    <div className="mx-2">
                      <label className="text-sm text-black41">{t("send_time")}</label>
                      <DatePicker
                        selected={tempFilterConfig.sendTimeFrom ?? null}
                        onChange={(newDate: Date | null) =>
                          setTempFilterConfig({
                            ...tempFilterConfig,
                            sendTimeFrom: newDate ?? undefined,
                            formattedSendTimeFrom: moment(newDate).locale("en").format("DD/MM/yyyy"),
                          })
                        }
                        placeholderText={t("from")}
                        maxDate={tempFilterConfig.sendTimeTo}
                      />
                    </div>

                    <div className="flex h-full items-end mt-2 sm:mt-0">
                      <DatePicker
                        selected={tempFilterConfig.sendTimeTo ?? null}
                        onChange={(newDate: Date | null) =>
                          setTempFilterConfig({
                            ...tempFilterConfig,
                            sendTimeTo: newDate ?? undefined,
                            formattedSendTimeTo: moment(newDate).locale("en").format("DD/MM/yyyy"),
                          })
                        }
                        placeholderText={t("to")}
                        minDate={tempFilterConfig.sendTimeFrom}
                      />
                    </div>
                  </div>

                  <div className="flex flex-1 items-center justify-end">
                    {hasSomeFilter() && (
                      <label
                        className="link mx-6"
                        onClick={() => {
                          setTempFilterConfig({ ...DEFAULT_FILTER_CONFIG, showFilterSection: true });
                          setFilterConfig(undefined);
                        }}
                      >
                        {t("reset")}
                      </label>
                    )}
                    <PrimaryButton onClick={() => setFilterConfig(tempFilterConfig)} primaryOrNot title={t("apply")} />
                  </div>
                </div>
              )}

              {hasSomeFilter() && (
                <div className="flex mx-8 items-center">
                  <label>{t("applied_filter")}</label>

                  {filterConfig?.statuses && !filterConfig?.statuses.includes(allItem) && (
                    <FilterTag
                      label={t("status")}
                      value={filterConfig.statuses.map((stat) => stat.name).join(",")}
                      onClose={() => {
                        setTempFilterConfig((preValue) => ({
                          ...preValue,
                          statuses: [allItem],
                        }));

                        setFilterConfig((preValue) => ({
                          ...preValue!,
                          statuses: [allItem],
                        }));
                      }}
                    />
                  )}

                  {(filterConfig?.sendTimeFrom || filterConfig?.sendTimeTo) && (
                    <FilterTag
                      label={t("send_time")}
                      value={sendTimeFilterTagValue}
                      onClose={() => {
                        setFilterConfig((preValue) => ({
                          ...preValue!,
                          sendTimeFrom: undefined,
                          formattedSendTimeFrom: undefined,
                        }));

                        setFilterConfig((preValue) => ({
                          ...preValue!,
                          sendTimeTo: undefined,
                          formattedSendTimeTo: undefined,
                        }));
                      }}
                    />
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-col bg-white mt-2 px-8 py-6">
              <ReceiverTable
                data={receivers ?? []}
                totalDataCount={totalReceiversCount}
                listIsLoading={receiverListIsLoading}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
                onResendClick={(receiverUniqueKey: string) =>
                  setShowResendModal({ receiverId: receiverUniqueKey, showModal: true })
                }
              />
            </div>
          </section>
        )}
      </main>
    </MainContainer>
  );
}
