import { faBan, faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import CustomTooltip from "../../../UI/CustomTooltip";
import { MessageStatus } from "../MsgModel";
import { useTranslation } from "react-i18next";

interface Props {
  onStopClick: () => void;
  onPreviewClick: () => void;
  messageStatus: MessageStatus;
  note?: string;
}

export default function Note(props: Props) {
  const { t } = useTranslation();
  const [showBanDescriptionTooltip, setShowBanDescriptionTooltip] = useState(false);

  return (
    <div className="flex items-center">
      <label className="flex-1">{props.note}</label>
      <div className="flex flex-1 justify-evenly">
        <div
          className="flex cursor-pointer ml-1 p-[6px] hover:bg-gray-f2 rounded-[3px]"
          onMouseEnter={() => setShowBanDescriptionTooltip(true)}
          onMouseLeave={() => setShowBanDescriptionTooltip(false)}
          style={{ visibility: props.messageStatus === MessageStatus.SD ? "visible" : "hidden" }}
        >
          <FontAwesomeIcon className="text-blue-primary" icon={faBan} onClick={props.onStopClick} />

          <CustomTooltip
            show={showBanDescriptionTooltip}
            position={{
              x: -70,
              y: 20,
            }}
          >
            {t("stop_scheduled_message")}
          </CustomTooltip>
        </div>

        <div className="flex cursor-pointer ml-1 p-[6px] hover:bg-gray-f2 rounded-[3px]">
          <FontAwesomeIcon className="text-blue-primary" icon={faEye} onClick={props.onPreviewClick} />
        </div>
      </div>
    </div>
  );
}
