import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { SelectedDefaultersContext } from "../../../store/SelectedDefaultersContext";
import i18next from "i18next";

const SelectedDefaulters: React.FC<{
  setShowResendReminderModal: (state: boolean) => void;
}> = ({ setShowResendReminderModal }) => {
  const { selectedDefaulters } = useContext(SelectedDefaultersContext);
  const [showHint, setShowHint] = useState(false);
  const { t } = useTranslation();

  if (selectedDefaulters.length === 0) {
    return null;
  }

  return (
    <section className="pt-[14px] pb-5 mb-2 bg-gray-f5">
      <header className="w-95/100 mx-auto flex items-center">
        <p className="text-sm text-green-primary">
          {selectedDefaulters.length === 1
            ? t("selected_record")
            : t("selected_records", {
                selectedRecordsNum: selectedDefaulters.length,
              })}
        </p>
        <div
          className="text-blue-primary cursor-pointer w-10 h-10 grid place-items-center rounded bg-[#8888882D] text-[20px] relative mx-2"
          onMouseOver={() => setShowHint(true)}
          onMouseLeave={() => setShowHint(false)}
          onClick={() => setShowResendReminderModal(true)}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
          {showHint && (
            <div
              className={`absolute ${
                i18next.language === "ar" ? "-left-[136px]" : "left-12"
              } top-[6px] w-32 h-7 grid place-items-center bg-white rounded shadow-lg`}
            >
              <div
                className={`w-2 h-2 bg-white absolute ${
                  i18next.language === "ar" ? "left-[124px]" : "-left-[4px]"
                } top-[10px] rotate-45 `}
              />
              <p className="text-black41 text-sm ">{t("resend_reminder")}</p>
            </div>
          )}
        </div>
      </header>
    </section>
  );
};

export default SelectedDefaulters;
