import { ContactListFilterConfig, ContactListSortConfig } from "../../components/main/messaging/contacts/ContactModel";
import { MsgListFilterConfig } from "../../components/main/messaging/MessageManagement";
import { MsgListSortConfig } from "../../components/main/messaging/MessagesTable";
import { MessageObj, MessageStatus } from "../../components/main/messaging/MsgModel";
import { ReceiverInfoFilter } from "../../components/main/messaging/PreviewMessage";
import { RIListSortConfig } from "../../components/main/messaging/ReceiverTable";
import useAPI from "../../hooks/useAPI";

const MsgManagementAPI = () => {
  const { sendRequest } = useAPI();

  const getMessageList = (
    onResponse: (response: Response) => void,
    pageNumber: number,
    pageSize: number,
    searchInput?: string,
    msgSortConfig?: MsgListSortConfig,
    msgFilterConfig?: MsgListFilterConfig
  ) => {
    const messageStatuses: MessageStatus[] = [];
    if (msgFilterConfig?.statuses) {
      msgFilterConfig.statuses.forEach((st) => {
        if (st.id) {
          const targetEnum = (Object.entries(MessageStatus) as [keyof typeof MessageStatus, string][]).find(
            ([_, enumValue]) => enumValue === st.id
          )?.[0] as MessageStatus;

          if (targetEnum) messageStatuses.push(targetEnum);
        }
      });
    }

    let apiRoute = `messages/?page=${pageNumber + 1}&size=${pageSize}${searchInput ? "&search=" + searchInput : ""}${
      msgFilterConfig?.sendTimeFrom ? "&send_date_from=" + msgFilterConfig.formattedSendTimeFrom : ""
    }${msgFilterConfig?.sendTimeTo ? "&send_date_to=" + msgFilterConfig.formattedSendTimeTo : ""}
    ${messageStatuses.map((st) => "&status=" + st).join("")}
    ${
      msgSortConfig
        ? "&ordering=" + (msgSortConfig.sortType === "Descending" ? `-${msgSortConfig.sortKey}` : msgSortConfig.sortKey)
        : ""
    }
    `;

    sendRequest(
      {
        url: apiRoute.replaceAll(" ", ""),
      },
      onResponse
    );
  };

  const stopSchedulingMessage = (uniqueKey: string, onResponse: (response: Response) => void) => {
    const apiRoute = `messages/${uniqueKey}/stop-schedule-message/`;
    sendRequest(
      {
        method: "PUT",
        url: apiRoute,
      },
      onResponse
    );
  };

  const getContacts = (
    onResponse: (response: Response) => void,
    pageNumber: number,
    pageSize: number,
    searchInput?: string,
    sortConfig?: ContactListSortConfig,
    contactFilterConfig?: ContactListFilterConfig
  ) => {
    const apiRoute = `messages/contact-list/?page=${pageNumber}&size=${pageSize}${
      searchInput ? "&search=" + searchInput : ""
    }${contactFilterConfig?.role && contactFilterConfig.role != -1 ? "&role=" + contactFilterConfig.role : ""}
    ${contactFilterConfig?.childAgeFromInDays ? "&child_days_from=" + contactFilterConfig.childAgeFromInDays : ""}${
      contactFilterConfig?.childAgeToInDays ? "&child_days_to=" + contactFilterConfig.childAgeToInDays : ""
    }
    ${contactFilterConfig?.clinicSlug ? "&clinics=" + contactFilterConfig.clinicSlug : ""}
    ${
      sortConfig
        ? "&ordering=" + (sortConfig.sortType === "Descending" ? `-${sortConfig.sortKey}` : sortConfig.sortKey)
        : ""
    }
    `;

    sendRequest(
      {
        url: apiRoute,
      },
      onResponse
    );
  };

  const getClinics = (onResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `clinic-list/`,
      },
      onResponse
    );
  };

  const sendSMS = (onResponse: (response: Response) => void, message: MessageObj, isScheduled: boolean) => {
    const body = {
      static_groups: message.staticGroups,
      dynamic_groups: message.dynamicGroups,
      is_scheduled: isScheduled,
      message_info: {
        note: message.messageInfo.note,
        text_message: message.messageInfo.textMessage,
        first_send_date: message.messageInfo?.firstFormattedDate,
        repeat_frequency_type: message.messageInfo?.repeatFrequencyType,
        repeat_frequency: message.messageInfo?.repeatFrequency,
        is_on_new_contacts: message.messageInfo?.repeatForNewContactsOnly,
        end_date: message.messageInfo?.endFormattedDate,
      },
    };

    sendRequest(
      {
        method: "POST",
        url: `messages/add/`,
        body: JSON.stringify(body),
      },
      onResponse
    );
  };

  const getMessage = (uniqueKey: string, onResponse: (response: Response) => void) => {
    sendRequest(
      {
        url: `messages/${uniqueKey}/view/`,
      },
      onResponse
    );
  };

  const resendMessage = (receiverUniqueKey: string, onResponse: (response: Response) => void) => {
    const apiRoute = `messages/${receiverUniqueKey}/resend-receiver-info/`;

    sendRequest(
      {
        method: "PUT",
        url: apiRoute,
      },
      onResponse
    );
  };

  const getMessageReceivers = (
    uniqueKey: string,
    onResponse: (response: Response) => void,
    pageNumber: number,
    pageSize: number,
    searchInput?: string,
    sortConfig?: RIListSortConfig,
    receiverFilterConfig?: ReceiverInfoFilter
  ) => {
    const messageStatuses: MessageStatus[] = [];
    if (receiverFilterConfig?.statuses) {
      receiverFilterConfig.statuses.forEach((st) => {
        if (st.id) {
          const targetEnum = (Object.entries(MessageStatus) as [keyof typeof MessageStatus, string][]).find(
            ([_, enumValue]) => enumValue === st.id
          )?.[0] as MessageStatus;

          if (targetEnum) messageStatuses.push(targetEnum);
        }
      });
    }

    const apiRoute = `messages/${uniqueKey}/view-receiver-info/?page=${pageNumber + 1}&size=${pageSize}${
      searchInput ? "&search=" + searchInput : ""
    }${receiverFilterConfig?.sendTimeFrom ? "&send_date_from=" + receiverFilterConfig.formattedSendTimeFrom : ""}${
      receiverFilterConfig?.sendTimeTo ? "&send_date_to=" + receiverFilterConfig.formattedSendTimeTo : ""
    }
    ${messageStatuses.map((st) => "&status=" + st).join("")}
    ${
      sortConfig
        ? "&ordering=" + (sortConfig.sortType === "Descending" ? `-${sortConfig.sortKey}` : sortConfig.sortKey)
        : ""
    }`;

    sendRequest(
      {
        url: apiRoute,
      },
      onResponse
    );
  };

  return {
    getMessageList,
    stopSchedulingMessage,
    getContacts,
    getClinics,
    sendSMS,
    getMessage,
    resendMessage,
    getMessageReceivers,
  };
};

export default MsgManagementAPI;
