import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

export enum DropdownType {
  PRIMARY,
  SECONDARY,
}

const Dropdown: React.FC<{
  type: DropdownType;
  keys: string[];
  selectedKey: string;
  onOptionSelected: (key: string) => void;
  widthClassName?: string;
  getValueByKey?: (key: string) => string;
  extraAvailableOptionsContainerClassName?: string;
}> = ({
  type,
  keys,
  selectedKey,
  onOptionSelected,
  widthClassName,
  getValueByKey,
  extraAvailableOptionsContainerClassName,
}) => {
  let rootElementclassName,
    selectedOptionClassName,
    selectedOptionDefaultState,
    selectedOptionClickedState,
    selectedOptionTextClassName,
    availableOptionsClassName: string,
    availableOptionsContainerClassName;

  switch (type) {
    case DropdownType.PRIMARY:
      rootElementclassName = widthClassName ?? "w-64";
      selectedOptionClassName =
        "flex justify-between items-center py-3 px-4 border border-gray-df rounded cursor-pointer hover:bg-white";
      selectedOptionDefaultState = "bg-gray-f6";
      selectedOptionClickedState = "border-blue-71 bg-white";
      selectedOptionTextClassName = "text-sm capitalize text-black41";
      availableOptionsClassName = "px-4 py-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6";
      availableOptionsContainerClassName = "top-12";
      break;
    case DropdownType.SECONDARY:
      rootElementclassName = widthClassName ?? "w-[72px]";
      selectedOptionClassName = "flex justify-between items-center p-2 border border-gray-df rounded cursor-pointer";
      selectedOptionDefaultState = "bg-white";
      selectedOptionClickedState = "border-blue-71 bg-white";
      selectedOptionTextClassName = "text-black41 text-sm";
      availableOptionsClassName = "p-2 capitalize text-sm text-black41 cursor-pointer hover:bg-gray-f6";
      availableOptionsContainerClassName = "top-10";
      break;
  }

  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div key={keys.toString()} className={`relative ${rootElementclassName}`} tabIndex={0} ref={dropdownRef}>
      <div
        className={`${selectedOptionClassName} ${showMenu ? selectedOptionClickedState : selectedOptionDefaultState}`}
        onClick={() => setShowMenu((preShowOptions) => !preShowOptions)}
      >
        <p className={selectedOptionTextClassName}>
          {getValueByKey ? getValueByKey(selectedKey) : t(selectedKey.toLowerCase().replace(" ", "_"))}
        </p>
        <FontAwesomeIcon className="text-gray-b5" icon={faAngleDown} />
      </div>
      {showMenu && (
        <ul
          className={`absolute left-0 right-0 bg-white rounded shadow py-1 z-40 ${availableOptionsContainerClassName} ${extraAvailableOptionsContainerClassName}`}
        >
          {keys.map((key) => (
            <li
              key={key}
              className={availableOptionsClassName}
              onClick={() => {
                setShowMenu(false);
                onOptionSelected(key);
              }}
            >
              {getValueByKey ? getValueByKey(key) : t(key.toLowerCase().replace(" ", "_"))}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
