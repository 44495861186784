import { useState } from "react";
import { MessageStatus, MessageStatusDetail, RepeatFrequency } from "../MsgModel";
import MsgStatusComponent, { getStatusBoxStyle } from "../MsgStatusComponent";
import CustomTooltip from "../../../UI/CustomTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface Props {
  receiversInfoStats: Array<MessageStatusDetail>;
  hasRepeat: boolean;
  repeatFrequncyType?: RepeatFrequency;
}

export default function MessageStatusCell(props: Props) {
  const { t } = useTranslation();
  const [showRepeatTooltip, setShowRepeatTooltip] = useState(false);

  return (
    <ul className="flex items-center">
      <div className="flex items-center justify-center">
        <MsgStatusComponent
          msgStatus={props.receiversInfoStats.length > 0 ? props.receiversInfoStats[0].status : MessageStatus.NN}
          key={props.receiversInfoStats.length.translateDigits()}
        />

        {props.receiversInfoStats.length > 0 && (
          <MessageStatusContainer msgStatusComponentArr={props.receiversInfoStats} />
        )}
      </div>

      {props.hasRepeat && (
        <div
          className="flex cursor-pointer mx-1"
          onMouseEnter={() => setShowRepeatTooltip(true)}
          onMouseLeave={() => setShowRepeatTooltip(false)}
        >
          <FontAwesomeIcon className="text-gray-8d hover:text-blue-primary" icon={faRepeat} />

          <CustomTooltip
            show={showRepeatTooltip}
            position={{
              x: 50,
              y: -20,
            }}
          >
            {`${t(props.repeatFrequncyType!.toLowerCase())} ${t("report")}`}
          </CustomTooltip>
        </div>
      )}
    </ul>
  );
}

interface MessageStatusContainerProps {
  msgStatusComponentArr: Array<MessageStatusDetail>;
}

function MessageStatusContainer(props: MessageStatusContainerProps) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="flex w-8 h-8 items-center justify-center p-2 text-blue-primary bg-gray-e8 rounded-[3px] hover:text-white hover:bg-blue-secondary cursor-pointer"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <label className="cursor-pointer">{props.msgStatusComponentArr.length.translateDigits()}</label>
      <CustomTooltip
        show={showTooltip}
        position={{
          x: 30,
          y: 10,
        }}
      >
        <div className="flex flex-col">
          {props.msgStatusComponentArr.map((msgSta, index) => (
            <div className="flex mt-2" key={index}>
              <label className={`mx-1 ${getStatusBoxStyle(msgSta.status)} bg-transparent`}>
                {msgSta.count.translateDigits()}
              </label>
              <label className={`mx-1 ${getStatusBoxStyle(msgSta.status)} bg-transparent`}>{t(msgSta.status)}</label>
            </div>
          ))}
        </div>
      </CustomTooltip>
    </div>
  );
}
