import DatePicker from "./DatePicker";

interface Props {
  selectedDate: Date | null;
  onDateOrTimeChanged: (newDate: Date | null) => void;
  placeHolder?: string;
  widthClassName?: string;
  minTime?: Date;
  maxTime?: Date;
  minDate?: Date;
  maxDate?: Date;
}

export default function DateAndTimePicker(props: Props) {
  return (
    <div className="flex flex-col items-center w-full">
      <DatePicker
        selected={props.selectedDate}
        onChange={(date) => props.onDateOrTimeChanged(date)}
        placeholderText={props.placeHolder ?? ""}
        timePickerConfig={{
          showTimePicker: true,
          timeFormat: "HH:mm",
          timeIntervals: 1,
          minTime: props.minTime,
          maxTime: props.maxTime,
        }}
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </div>
  );
}
