import React, { useContext, useEffect, useState } from "react";
import MainContainer from "../../UI/MainContainer";
import { AuthContext } from "../../../store/AuthContext";
import HeaderLink from "./HeaderLink";
import { useTranslation } from "react-i18next";
import resetprofile from "../../../images/resetPass.svg";
import MainFooter from "../../UI/MainFooter";
import PasswordInput from "../../UI/PasswordInput";
import Button from "../../UI/Button";
import usePasswordValidation, { passwordValidator } from "../../../hooks/UsePasswordValidation";
import zxcvbn from "zxcvbn";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { validationState, ButtonTypes } from "../../../utils/Constants";
import PasswordProgressBar from "../../UI/PasswordProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { AccountSettingContext } from "../../../store/AccountSettingContext";
import i18next from "i18next";

const ChangePassword: React.FC<{
  cancel: () => void;
  saveChangePassword: (currentPassword: string, secondPassword: string) => void;
}> = ({ cancel, saveChangePassword }) => {
  const { authData } = useContext(AuthContext);
  const { t } = useTranslation();
  const FIRST_PASSSWORD_ERROR_MESSAGE = t("first_password_error");
  const SECOND_PASSSWORD_ERROR_MESSAGE = t("repeat_new_password_description");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showHint, setShowHint] = useState(false);
  const { currentPasswordHasError, setCurrentPasswordHasError, passwordErrorText, setPasswordErrorText, loading } =
    useContext(AccountSettingContext);

  useEffect(() => {
    setCurrentPasswordHasError(false);
    setPasswordErrorText("");
  }, []);

  const enum errorType {
    FIRST_PASSWORD,
    SECOND_PASSWORD,
    CAPTCHA,
    NONE,
  }

  const [error, setError] = useState<{
    type: errorType;
    message?: string;
  }>({ type: errorType.NONE });

  const {
    password: firstPassword,
    setPassword: setFirstPassword,
    passwordHasError: firstPasswordHasError,
    setPasswordHasError: setFirstPasswordHasError,
  } = usePasswordValidation();

  const {
    password: secondPassword,
    setPassword: setSecondPassword,
    passwordHasError: secondPasswordHasError,
    setPasswordHasError: setSecondPasswordHasError,
  } = usePasswordValidation((secondPass) => secondPass === firstPassword);

  const [firstPasswordStrength, setFirstPasswordStrength] = useState<{
    label: string;
    value: number;
    color: string;
  }>({
    label: "",
    value: 0,
    color: "",
  });

  const WEAK = {
    label: t("weak"),
    value: 40,
    color: "#D11C1C",
  };
  const Medium = {
    label: t("medium"),
    value: 60,
    color: "#F78E00",
  };
  const STRONG = {
    label: t("strong"),
    value: 100,
    color: "#0AA69F",
  };

  useEffect(() => {
    switch (firstPasswordHasError) {
      case validationState.INVALID:
        setError({
          type: errorType.FIRST_PASSWORD,
          message: FIRST_PASSSWORD_ERROR_MESSAGE,
        });
        break;
      case validationState.VALID:
        if (secondPassword !== undefined && firstPassword !== secondPassword) {
          setError({
            type: errorType.SECOND_PASSWORD,
            message: SECOND_PASSSWORD_ERROR_MESSAGE,
          });
        } else {
          setError({ type: errorType.NONE });
        }
        break;
      case validationState.WAITING:
        setError({ type: errorType.NONE });
        break;
    }
  }, [firstPasswordHasError]);

  useEffect(() => {
    if (secondPasswordHasError === validationState.INVALID) {
      setError({
        type: errorType.SECOND_PASSWORD,
        message: SECOND_PASSSWORD_ERROR_MESSAGE,
      });
    } else {
      setError({ type: errorType.NONE });
    }
  }, [secondPasswordHasError]);

  useEffect(() => {
    if (firstPassword !== undefined) {
      const passwordStrength = zxcvbn(firstPassword);

      if (passwordValidator(firstPassword)) {
        if (passwordStrength.score <= 2) {
          setFirstPasswordStrength(Medium);
        } else if (passwordStrength.score >= 3) {
          setFirstPasswordStrength(STRONG);
        }
      } else {
        setFirstPasswordStrength(WEAK);
      }
    }
  }, [firstPassword]);

  const firstPasswordIsEmpty = firstPassword === "" || firstPassword === undefined;

  const saveChangeDisabled =
    currentPassword === "" ||
    firstPassword === undefined ||
    secondPassword === undefined ||
    !passwordValidator(firstPassword) ||
    firstPassword !== secondPassword;

  const ChangePasswordHandler = () => {
    if (
      firstPassword !== undefined &&
      currentPassword !== "" &&
      secondPassword !== undefined &&
      passwordValidator(firstPassword) &&
      firstPassword === secondPassword
    ) {
      saveChangePassword(currentPassword, secondPassword);
    }
  };

  return (
    <MainContainer>
      <div className="alignment">
        <div className="rounded shadow bg-white mt-[26px] relative ">
          <HeaderLink />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              ChangePasswordHandler();
            }}
          >
            <div className="w-[97%] mx-auto mt-[30px] text-[16px] text-[#414141] pb-[70px]">
              <div>{authData.email}</div>
              <div className="text-sm text-[#272727] mt-10">
                <div>
                  {t("current_password")} <span className="text-red-primary">*</span>
                </div>
                <div className="mt-[15px] sm:w-1/3  h-[70px] z-10 relative">
                  <PasswordInput
                    hasError={currentPasswordHasError}
                    onPasswordChange={(pass) => {
                      setCurrentPassword(pass);
                    }}
                    placeholder=""
                  />

                  <p className="input__error-message mt-2">{passwordErrorText}</p>
                </div>
              </div>
              <div className="text-sm text-[#030303] mt-[35px]">
                <div className="sm:w-1/3 h-[120px] z-10 relative">
                  <div>
                    {t("new_password")} <span className="text-red-primary">*</span>
                  </div>
                  <div className="mt-[15px]  ">
                    <PasswordInput
                      hasError={error.type === errorType.FIRST_PASSWORD}
                      onPasswordChange={(value) => {
                        setFirstPassword(value);
                        setFirstPasswordHasError(validationState.WAITING);
                      }}
                      placeholder=""
                    />
                    {firstPasswordIsEmpty ? (
                      <p className="text-xs text-gray-70 mt-1 first-letter:uppercase">
                        {t("new_password_description")}
                      </p>
                    ) : (
                      <div className="flex items-center mt-1 relative" dir="ltr">
                        <PasswordProgressBar {...firstPasswordStrength} className="flex-1 mr-2" />
                        <FontAwesomeIcon
                          data-testid="info-icon"
                          icon={faInfoCircle}
                          className="cursor-pointer text-sm text-gray-70"
                          onMouseOver={() => setShowHint(true)}
                          onMouseLeave={() => setShowHint(false)}
                        />
                        {showHint && (
                          <p
                            className="absolute w-[165px] bg-white rounded p-2 text-xs text-gray-27 -right-4 top-[-92px] z-10 shadow first-letter:uppercase"
                            dir={i18next.language === "ar" ? "rtl" : "ltr"}
                          >
                            {t("new_password_description")}
                          </p>
                        )}
                      </div>
                    )}
                    {error.type === errorType.FIRST_PASSWORD && (
                      <p className="input__error-message mt-1">{error.message}</p>
                    )}
                  </div>
                </div>
                <div className="text-sm text-[#272727] mt-[30px]">
                  {t("repeat_new_password")} <span className="text-red-primary">*</span>
                </div>
                <div className="mt-[15px] sm:w-1/3 h-[60px] z-10 relative">
                  <PasswordInput
                    hasError={error.type === errorType.SECOND_PASSWORD}
                    onPasswordChange={(value) => {
                      setSecondPassword(value);
                      setSecondPasswordHasError(validationState.WAITING);
                    }}
                    disabled={error.type === errorType.FIRST_PASSWORD || firstPasswordIsEmpty}
                    placeholder="repeat your password"
                  />
                  {error.type === errorType.SECOND_PASSWORD && (
                    <p className="input__error-message mt-2">{error.message}</p>
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="mt-10 sm:w-1/3 flex flex-row-reverse sm:mx-[0px] px-[30px] sm:px-0 mx-auto z-10 relative">
                  <Button
                    onClick={() => {
                      ChangePasswordHandler();
                    }}
                    type={ButtonTypes.PRIMARY}
                    disabled={saveChangeDisabled}
                  >
                    {loading ? <CircularProgress size={18} style={{ color: "white" }} /> : t("save")}
                  </Button>
                  <button
                    onClick={() => {
                      cancel();
                    }}
                    className="rounded px-[25px] mx-4 capitalize text-[#414141] hover:bg-[#F5F3F2]"
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div
            className={`absolute w-[580px] bottom-0 ${
              i18next.language === "ar" ? "left-0" : "right-0"
            } invisible sm:visible z-0`}
          >
            <img
              src={resetprofile}
              alt={t("background")}
              style={i18next.language === "ar" ? { transform: "scaleX(-1)" } : {}}
            />
          </div>
        </div>

        <MainFooter />
      </div>
    </MainContainer>
  );
};

export default ChangePassword;
